<script>
import {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    MailIcon
} from 'vue-feather-icons';
export default {
    data() {
        return {

        }
    },
    components: {
        FacebookIcon,
        InstagramIcon,
        TwitterIcon,
        LinkedinIcon,
        MailIcon
    }
}
</script>

<template>
<div>
    <!-- Footer Start -->
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                    <a href="#" class="logo-footer">
                        <img src="images/logo-light.png" height="28" alt="">
                    </a>
                    <p class="mt-4">地址：深圳市南山区学府路与科技园交汇处软件产业基地1栋C座19楼</p>
                    <p class="mt-4">电话：0755-86720281</p>
                    <p class="mt-4">邮箱：kefu@91qt.com</p>
                    <!--end icon-->
                </div>
                <!--end col-->

                <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <h4 class="text-light footer-head">公司简介</h4>
                    <ul class="list-unstyled footer-list mt-4">
                        <li>
                            <router-link to="/" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> 首页</router-link>
                        </li>
                        <li>
                            <router-link to="/index-insurance" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> 产品及服务</router-link>
                        </li>
                        <li>
                            <router-link to="/page-aboutus-two" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> 关于我们</router-link>
                        </li>
                        <li>
                            <router-link to="/helpcenter-support-request" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> 联系我们</router-link>
                        </li>
                        <li>
                            <router-link to="/page-blog-list" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> 新闻资讯</router-link>
                        </li>
                    </ul>
                </div>
                <!--end col-->

                <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <h4 class="text-light footer-head">友情链接</h4>
                    <ul class="list-unstyled footer-list mt-4">
                        <li>
                            <a class="text-foot" href="https://etax.shenzhen.chinatax.gov.cn/bszm-web/apps/views/beforeLogin/indexBefore/pageIndex.html#/" target="_blank"><i class="mdi mdi-chevron-right mr-1"></i> 深圳税务局</a>
                        </li>
                        <li>
                            <a class="text-foot" href="https://bank.pingan.com/" target="_blank"><i class="mdi mdi-chevron-right mr-1"></i> 平安银行</a>
                        </li>
                        <li>
                            <a class="text-foot" href="https://www.spdb.com.cn/" target="_blank"><i class="mdi mdi-chevron-right mr-1"></i> 浦发银行</a>
                        </li>
                        <li>
                            <a class="text-foot" href="https://etax.jiangxi.chinatax.gov.cn/etax/jsp/index.jsp" target="_blank"><i class="mdi mdi-chevron-right mr-1"></i> 江西税务局</a>
                        </li>
                        <li>
                            <a class="text-foot" href="http://hainan.chinatax.gov.cn/" target="_blank"><i class="mdi mdi-chevron-right mr-1"></i> 海南税务局</a>
                        </li>
                    </ul>
                </div>
                <!--end col-->

                <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <!-- <h4 class="text-light footer-head">联系我们</h4> -->
                    <img src="images/qrcode.jpg" height="130" alt="">
                    <p class="mt-4">扫描二维码关注青桐公众号</p>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </footer>
    <!--end footer-->
    <footer class="footer footer-bar">
        <div class="container text-center">
            <div class="row align-items-center">
                <div class="col-sm-6">
                    <div class="text-sm-left">
                        <p class="mb-0">© 2020 深圳青桐盛夏科技有限公司.
                        <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备17111153号-1</a>
                    </p>
                    </div>
                </div>
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </footer>
    <!--end footer-->
</div>
</template>
