<script>
import {
    ArrowUpIcon,
    UserIcon,
    BookIcon,
    MessageCircleIcon,
    MailIcon
} from 'vue-feather-icons';

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";

/**
 * Helpcenter-support-request component
 */
export default {
    data() {
        return {
            name: "",
            mobile: "",
            company: "",
            email: "",
            remark: ""
        }
    },
    components: {
        Navbar,
        Switcher,
        Footer,
        ArrowUpIcon,
        UserIcon,
        BookIcon,
        MessageCircleIcon,
        MailIcon
    },
    created() {
    },
    methods: {
        resetInfo() {
            this.name = ""
            this.mobile = ""
            this.company = ""
            this.email = ""
            this.remark = ""
        },
        // 收集用户提交的信息
        sumbitInfo (e) {
            e.preventDefault();
            const _data = {
                name: this.name,
                mobile: this.mobile,
                company: this.company,
                email: this.email,
                remark: this.remark
            }
            if (!this.name && !this.mobile && !this.company) {
                this.$bvToast.toast(`请填写联系信息！`, {
                    title: '提交',
                    toaster: 'b-toaster-top-right'
                })
            } else {
                this.$axios.post('https://centerapi.91qt.com/api/baidu/user/add', _data)
                .then(res => {
                    console.log("=====打印提交的信息=====", res);
                    this.$bvToast.toast(`提交成功，稍后客户经理会与您联系`, {
                        title: '提交',
                        toaster: 'b-toaster-top-right'
                    })
                    setTimeout(() => {
                        this.resetInfo();
                    }, 2000);
                })
                .catch(error => {
                    console.log(error)
                })
            }
        }
    }
}
</script>

<template>
<div>
   <!-- <Navbar :is-white-navbar="true" /> -->
   <Navbar :nav-light="true" />

    <!-- Hero Start -->
    <section class="bg-half d-table w-100" style="background: url('images/company/banner2.jpg') center center;">
        <!-- <div class="bg-overlay"></div> -->
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level title-heading">
                        <h1 class="text-white title-dark"> 联系我们 </h1>
                        <div class="page-next">
                            <nav aria-label="breadcrumb" class="d-inline-block">
                                <ul class="breadcrumb bg-white rounded shadow mb-0">
                                    <li class="breadcrumb-item">
                                        <router-link to="/">首页</router-link>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">联系我们</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!-- Hero End -->

    <!-- Start Section -->
    <section class="section">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="card contact-detail text-center border-0">
                        <div class="card-body p-0">
                            <div class="icon">
                                <img src="images/icon/bitcoin.svg" class="avatar avatar-small" alt="">
                            </div>
                            <div class="content mt-3">
                                <h4 class="title font-weight-bold">电话</h4>
                                <p class="text-muted">拨打电话，开始合作</p>
                                <a href="tel:+152534-468-854" class="text-primary">0755-86720281</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="card contact-detail text-center border-0">
                        <div class="card-body p-0">
                            <div class="icon">
                                <img src="images/icon/Email.svg" class="avatar avatar-small" alt="">
                            </div>
                            <div class="content mt-3">
                                <h4 class="title font-weight-bold">邮箱</h4>
                                <p class="text-muted">发送邮件，开始合作</p>
                                <a href="mailto:contact@example.com" class="text-primary">kefu@91qt.com</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="card contact-detail text-center border-0">
                        <div class="card-body p-0">
                            <div class="icon">
                                <img src="images/icon/location.svg" class="avatar avatar-small" alt="">
                            </div>
                            <div class="content mt-3">
                                <h4 class="title font-weight-bold">地址</h4>
                                <p class="text-muted">深圳南山区软件产业基地1栋C座19楼</p>
                                <a href="https://map.baidu.com/" class="video-play-icon text-primary">在百度地图上搜索</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->

        <div class="container mt-100 mt-60">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-6 pt-2 pt-sm-0 order-2 order-md-1">
                    <div class="card shadow rounded border-0">
                        <div class="card-body py-5">
                            <h4 class="card-title">取得联系 !</h4>
                            <div class="custom-form mt-4">
                                <div id="message"></div>
                                <form name="contact-form" id="contact-form">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group position-relative">
                                                <label>您的姓名 <span class="text-danger">*</span></label>
                                                <user-icon class="fea icon-sm icons"></user-icon>
                                                <input v-model="name" name="name" id="name" type="text" class="form-control pl-5" placeholder="请输入您的姓名 :" required="required">
                                            </div>
                                        </div>
                                        <!--end col-->
                                        <div class="col-md-6">
                                            <div class="form-group position-relative">
                                                <label>您的邮箱 <span class="text-danger">*</span></label>
                                                <mail-icon class="fea icon-sm icons"></mail-icon>
                                                <input v-model="email"  name="email" id="email" type="email" class="form-control pl-5" placeholder="请输入您的邮箱 :">
                                            </div>
                                        </div>
                                        <!--end col-->
                                        <div class="col-md-6">
                                            <div class="form-group position-relative">
                                                <label>您的手机 <span class="text-danger">*</span></label>
                                                <mail-icon class="fea icon-sm icons"></mail-icon>
                                                <input v-model="mobile" name="mobile" id="mobile" type="text" class="form-control pl-5" placeholder="请输入您的手机  :">
                                            </div>
                                        </div>
                                        <!--end col-->
                                        <div class="col-md-12">
                                            <div class="form-group position-relative">
                                                <label>公司<span class="text-danger">*</span></label>
                                                <book-icon class="fea icon-sm icons"></book-icon>
                                                <input v-model="company" name="company" id="company" type="text" class="form-control pl-5" placeholder="请输入公司名称">
                                            </div>
                                        </div>
                                        <!--end col-->
                                        <div class="col-md-12">
                                            <div class="form-group position-relative">
                                                <label>留言内容</label>
                                                <message-circle-icon class="fea icon-sm icons"></message-circle-icon>
                                                <textarea v-model="remark" name="remark" id="remark" rows="1" class="form-control pl-5" placeholder="请输入留言内容"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end row-->
                                    <div class="row">
                                        <div class="col-sm-12 text-center">
                                            <!-- <input class="submitBnt btn btn-primary btn-block" value="提交"> -->
                                            <button  class="btn btn-primary btn-block" @click="sumbitInfo($event)">提交</button>
                                            <div id="simple-msg"></div>
                                        </div>
                                        <!--end col-->
                                    </div>
                                    <!--end row-->
                                </form>
                                <!--end form-->
                            </div>
                            <!--end custom-form-->
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-7 col-md-6 order-1 order-md-2">
                    <div class="card border-0">
                        <div class="card-body p-0">
                            <img src="images/contact.svg" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->

        <!-- <div class="container-fluid mt-100 mt-60">
            <div class="row">
                <div class="col-12 p-0">
                    <div class="card map border-0">
                        <div class="card-body p-0">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin" style="border:0" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!--end container-->
    </section>
    <!--end section-->
    <!-- End Section -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top" v-scroll-to="{el: '#topnav', duration: 300}">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>
                                           