<script>
import {
    ArrowUpIcon,
    MonitorIcon,
    HeartIcon,
    EyeIcon,
    BoldIcon,
    FeatherIcon,
    CodeIcon,
    UserCheckIcon,
    GitMergeIcon,
    SettingsIcon
} from 'vue-feather-icons';
import countTo from 'vue-count-to';

import {
    Carousel,
    Slide
} from 'vue-carousel';

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
/**
 * Index-marketing component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        Switcher,
        Footer,
        Carousel,
        Slide,
        ArrowUpIcon,
        MonitorIcon,
        HeartIcon,
        EyeIcon,
        BoldIcon,
        FeatherIcon,
        CodeIcon,
        UserCheckIcon,
        GitMergeIcon,
        SettingsIcon,
        countTo
    }
}
</script>

<template>
<div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-marketing d-table w-100" style="background: url('images/marketing/marketing-shape.png')" id="home">
        <div class="container">
            <div class="row justify-content-center mt-5">
                <div class="col-lg-7 col-md-7 text-center">
                    <img src="images/marketing/marketing.png" class="img-fluid" style="max-height: 400px" alt="">

                    <div class="title-heading mt-0 mt-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <!-- <h1 class="heading mb-3">业务流程外包</h1> -->
                        <!-- <p class="para-desc text-muted">Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap4 html page.</p> -->
                    </div>
                </div>
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Feature Start -->
    <section class="section pt-0">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-6">
                    <img src="images/jimi.png" alt="" class="img-fluid" height="300">
                </div>
                <!--end col-->

                <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="section-title ml-lg-5">
                        <h4 class="title mb-4">业务流程外包BPO</h4>
                        <p class="text-muted">业务流程外包（Business Process Outsourcing），就是企业将一些重复性的非核心或核心业务流程外包给供应商，以降低成本，同时提高服务质量。BPO远远超出了咨询的范围。它能够实质上增加公司的价值；从项目工程的开发，客户的管理，风险的评估，人力资源的管理等等方面，进入BPO业务能让公司内部更加的活跃，让公司关注的市盈率评价体系更加完善，公司也将朝着更加理性的方向发展。</p>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->

        
        <!--end container-->
        <!-- <div class="container mt-100 mt-60">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-6 mt-4 pt-2 order-1 order-md-2">
                    <img src="images/jimi.png" alt="" style="max-height: 450px;">
                </div>

                <div class="col-lg-7 col-md-6 mt-4 pt-2 order-2 order-md-1">
                    <div class="section-title mr-lg-5">
                        <h4 class="title mb-4">业务模式</h4>
                        <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a component from the Application theme and use it in the Website.</p>
                        <ul class="list-unstyled text-muted">
                            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Digital Marketing Solutions for Tomorrow</li>
                            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Our Talented & Experienced Marketing Agency</li>
                            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Create your own skin to match your brand</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="container mt-100 mt-60">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="section-title mb-4 pb-2">
                        <h4 class="title mb-4">业务流程外包给企业带来的好处</h4>
                        <p class="text-muted">助力企业降本增效，HR创造更优价值，打造高效服务闭环，让客户更加省心.</p>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

            <div class="row" id="counter">
                <div class="col-md-3 col-6 mt-4 pt-2">
                    <div class="counter-box text-center">
                        <img src="images/illustrator/Asset190.svg" class="avatar avatar-small" alt="">
                        <h4 class="mb-0 mt-4">
                            风险转嫁
                        </h4>
                        <h6 class="counter-head text-muted pt-2">将传统用工转化为社会用工，转嫁用工风险，转移和规避部分经营风险.</h6>
                    </div>
                    <!--end counter box-->
                </div>

                <div class="col-md-3 col-6 mt-4 pt-2">
                    <div class="counter-box text-center">
                        <img src="images/illustrator/Asset189.svg" class="avatar avatar-small" alt="">
                        <h4 class="mb-0 mt-4">
                            降低成本
                        </h4>
                        <h6 class="counter-head text-muted pt-2">以量计算，预算便捷、准确，服务具备专业规模和资源优势，有效降低成本.</h6>
                    </div>
                    <!--end counter box-->
                </div>

                <div class="col-md-3 col-6 mt-4 pt-2">
                    <div class="counter-box text-center">
                        <img src="images/illustrator/Asset192.svg" class="avatar avatar-small" alt="">
                        <h4 class="mb-0 mt-4">
                            提升竞争力
                        </h4>
                        <h6 class="counter-head text-muted pt-2">以结果为导向，按要求的品质交期和成本等为服务考核标准，客户只需对过程监管和考核从而全面提升竞争力.</h6>
                    </div>
                    <!--end counter box-->
                </div>

                <div class="col-md-3 col-6 mt-4 pt-2">
                    <div class="counter-box text-center">
                        <img src="images/illustrator/Asset187.svg" class="avatar avatar-small" alt="">
                        <h4 class="mb-0 mt-4">
                            战略帮手
                        </h4>
                        <h6 class="counter-head text-muted pt-2">实现战略落地好帮手，你补企业短板和隐忧，能快速实现阶段性目标</h6>
                    </div>
                    <!--end counter box-->
                </div>
            </div>
            <!--end row-->
        </div>
    </section>
    <!--end section-->
    <!-- Feature End -->

     <!-- Feature Start -->
    <!-- <section class="section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="section-title ">
                        <h4 class="title mb-4">业务模式</h4>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="counter-box text-center">
                        <img src="images/jimi.png" alt="" style="max-height: 450px">
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <!--end section-->
    <!-- Feature End -->

    <!-- Faq Start -->
    <section class="section">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-6">
                    <h2 class="title mb-4 pb-2">服务场景</h2>
                    <div class="faq-content mr-lg-5">
                        <div class="accordion" id="accordionExampleone">
                            <b-card no-body class="mb-2 border-0 rounded">
                                <b-card-header class="border-0 bg-light p-3 pr-5">
                                    <a class="faq position-relative text-dark" v-b-toggle.accordion-1 href="javascript: void(0);">
                                        <h6 class="title mb-0">客服服务</h6>
                                    </a>
                                </b-card-header>
                                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                                    <b-card-body class="px-2 py-4">
                                        <p class="text-muted mb-0 faq-ans">电商类、电信类客服服务</p>
                                        <p class="text-muted mb-0 faq-ans">民生政事类客服服务</p>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-2 border-0 rounded">
                                <b-card-header class="border-0 bg-light p-3 pr-5">
                                    <a class="faq position-relative text-dark" v-b-toggle.accordion-2 href="javascript: void(0);">
                                        <h6 class="title mb-0">内容安全</h6>
                                    </a>
                                </b-card-header>
                                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                                    <b-card-body class="px-2 py-4">
                                        <p class="text-muted mb-0 faq-ans">直播类业务互动安全实时巡查监管</p>
                                        <p class="text-muted mb-0 faq-ans">论坛、平台内容合规监管</p>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-2 border-0 rounded">
                                <b-card-header class="border-0 bg-light p-3 pr-5">
                                    <a class="faq position-relative text-dark" v-b-toggle.accordion-3 href="javascript: void(0);">
                                        <h6 class="title mb-0">电话营销</h6>
                                    </a>
                                </b-card-header>
                                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                                    <b-card-body class="px-2 py-4">
                                        <p class="text-muted mb-0 faq-ans">外呼、用研、营销类呼叫中心</p>
                                        <p class="text-muted mb-0 faq-ans">金融类合法催收业务</p>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>

                            <b-card no-body class="mb-2 border-0 rounded">
                                <b-card-header class="border-0 bg-light p-3 pr-5">
                                    <a class="faq position-relative text-dark" v-b-toggle.accordion-5 href="javascript: void(0);">
                                        <h6 class="title mb-0"> 数据标注 </h6>
                                    </a>
                                </b-card-header>
                                <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
                                    <b-card-body class="px-2 py-4">
                                        <p class="text-muted mb-0 faq-ans">短视频、音视频业务分类标注</p>
                                        <p class="text-muted mb-0 faq-ans">地图、图片、AI类业务标注</p>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <img src="images/illustrator/faq.svg" alt="">
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->

    </section>
    <!-- Testi Start -->
    <section class="section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="section-title mb-4 pb-2">
                        <h4 class="title mb-4">服务优势</h4>
                        <p class="text-muted para-desc mx-auto mb-0">四大优势，放心选择青桐盛夏BPO.</p>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

            <div class="row">
                <div class="col-12 mt-4">
                    <carousel id="customer-testi" class="owl-carousel owl-theme" dir="ltr" :per-page="4">
                        <Slide>
                            <div class="card customer-testi text-center border-0 shadow rounded m-2">
                                <div class="card-body">
                                    <img src="images/product/1.png" class="img-fluid avatar mx-auto" alt="">
                                    <h6 class="text-primary mt-4">产业扶持</h6>
                                    <p class="text-muted">政府扶持力度大，成本最低</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide>
                            <div class="card customer-testi text-center border-0 shadow rounded m-2">
                                <div class="card-body">
                                    <img src="images/product/2.png" class="img-fluid avatar mx-auto" alt="">
                                    <h6 class="text-primary mt-4">人才充足</h6>
                                    <p class="text-muted">招聘团队+基地培养</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide>
                            <div class="card customer-testi text-center border-0 shadow rounded m-2">
                                <div class="card-body">
                                    <img src="images/product/3.png" class="img-fluid avatar  mx-auto" alt="">
                                    <h6 class="text-primary mt-4">硬件条件优良</h6>
                                    <p class="text-muted">场地大，职场基地多，设备齐全，运营系统全面</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide>
                            <div class="card customer-testi text-center border-0 shadow rounded m-2">
                                <div class="card-body">
                                    <img src="images/product/3.png" class="img-fluid avatar  mx-auto" alt="">
                                    <h6 class="text-primary mt-4">业务专业</h6>
                                    <p class="text-muted">业务专注，过往运营经验足</p>
                                </div>
                            </div>
                        </Slide>
                    </carousel>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
    </section>
    <!--end section-->
    <!--end section-->
    <!-- Faq End -->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>
