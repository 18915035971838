<script>
import {
  ArrowUpIcon,
  UserIcon,
  MailIcon,
  ArrowRightIcon,
  HelpCircleIcon,
} from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";

/**
 * Index-insurance component
 */
export default {
  data() {
    return {};
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    ArrowUpIcon,
    Carousel,
    Slide,
    UserIcon,
    MailIcon,
    ArrowRightIcon,
    HelpCircleIcon,
  },
};
</script>

<template>
  <div>
    <Navbar :nav-light="true" />

    <!-- Hero Start -->
    <section
      class="bg-half bg-primary d-table w-100"
      style="background: url('images/insurance/bg.png') center center;"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level title-heading">
              <h1 class="text-white title-dark">人才外包</h1>
              <!-- <p class="text-white-50 para-desc mb-0 mx-auto">开始与青桐盛夏合作，我们可以提供你所需的一切，以提高效率，建立联系.</p> -->
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item">
                      <router-link to="/">首页</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      人才外包
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
    </section>
    <!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!-- Hero End -->
    <section class="section">
      <div class="container mb-2">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title">
              <h4 class="title mb-4">企业难题/服务场景</h4>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
          <div class="col-lg-2 col-md-4 col-6 mt-4 pt-2">
            <div class="card explore-feature rounded text-center bg-white">
              <div class="card-body">
                <div
                  class="d-inline-block h1"
                >
                  <!-- <i class="uil uil-user-plus"></i> -->
                  <img src="images/rencai3.png" class="avatar avatar-md-md" alt="">
                </div>
                <div class="content mt-2">
                  <h6>
                    <a href="javascript:void(0);" class="title text-dark"
                      >编制预算严</a
                    >
                  </h6>
                  <p class="text-muted" style="font-size:12px;">
                    人员编制申请难，工资总额预算刚性
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
          <div class="col-lg-2 col-md-4 col-6 mt-4 pt-2">
            <div class="card explore-feature rounded text-center bg-white">
              <div class="card-body">
                <div
                  class="d-inline-block h1"
                >
                  <img src="images/rencai4.png" class="avatar avatar-md-md" alt="">
                </div>
                <div class="content mt-2">
                  <h6>
                    <a href="javascript:void(0);" class="title text-dark"
                      >用工风险高</a
                    >
                  </h6>
                  <p class="text-muted" style="font-size:12px;">
                    用工波动，企业主体直接争议风险记录
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
          <div class="col-lg-2 col-md-4 col-6 mt-4 pt-2">
            <div class="card explore-feature rounded text-center bg-white">
              <div class="card-body">
                <div
                  class="d-inline-block h1"
                >
                  <img src="images/rencai5.png" class="avatar avatar-md-md" alt="">
                </div>
                <div class="content mt-2">
                  <h6>
                    <a href="javascript:void(0);" class="title text-dark"
                      >管理难度大</a
                    >
                  </h6>
                  <p class="text-muted" style="font-size:12px;">
                    管理难度越来越大，组织臃肿分类复杂
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
          <div class="col-lg-2 col-md-4 col-6 mt-4 pt-2">
            <div class="card explore-feature rounded text-center bg-white">
              <div class="card-body">
                <div
                  class="d-inline-block h1"
                >
                  <img src="images/rencai6.png" class="avatar avatar-md-md" alt="">
                </div>
                <div class="content mt-2">
                  <h6>
                    <a href="javascript:void(0);" class="title text-dark"
                      >属地城市多</a
                    >
                  </h6>
                  <p class="text-muted" style="font-size:12px;">
                    全国地域差异大外派及属地管理难
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
          <div class="col-lg-2 col-md-4 col-6 mt-4 pt-2">
            <div class="card explore-feature rounded text-center bg-white">
              <div class="card-body">
                <div
                  class="d-inline-block h1"
                >
                  <img src="images/rencai7.png" class="avatar avatar-md-md" alt="">
                </div>
                <div class="content mt-2">
                  <h6>
                    <a href="javascript:void(0);" class="title text-dark"
                      >用工成本高</a
                    >
                  </h6>
                  <p class="text-muted" style="font-size:12px;">
                    用工需求阶段波动，全职雇佣成本高
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
      </div>
    </section>

    <section class="section bg-light">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-12 col-md-12">
            <div class="section-title ml-lg-5 text-center">
              <h4 class="title mb-4">
                更适合企业的一体化用工解决方案服务<br /><span
                  class="text-primary font-weight-bold"
                  >人才外包</span
                >
              </h4>
              <p class="text-muted">
                青桐盛夏致力于灵活用工服务，致力于为企业提供专业、定制、合法合规的用工解决方案。帮助雇主从雇佣1.0模式下的
                “标准雇佣关系”
                进入灵活用工2.0模式。通过人员外包岗位外包、项目外包、劳务外包、劳务派遣、兼职等多种
                “非标准雇佣模式”，为企业提供高性价比的人力资源服务。从而实现灵活的需求匹配与调整空间，以增加组织弹性和敏捷性，降低用工风险，达到增效降本提升企业商业价值，依托于数字化灵活用工平台，为企业提供定制化人力资源外包服务，助力企业聚焦核心业务，提升企业管理效能。
              </p>
              <!-- <div class="mt-4">
                <router-link
                  to="/helpcenter-support-request"
                  class="btn btn-primary"
                  >预约咨询
                  <arrow-right-icon class="fea icon-sm"></arrow-right-icon>
                </router-link>
              </div> -->
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>

    <section class="section" style="padding-top: 60px;">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title">
              <h4 class="title mb-4 pb-4">服务介绍</h4>
            </div>
          </div>
          <!--end col-->
        </div>
        <div class="row justify-content-center">
          <div class="col-md-5 mt-sm-0 pt-sm-0">
            <div
              class="media features p-4 bg-white shadow rounded align-items-center"
            >
              <div
                class="rounded-circle p-3"
                style="background-color: rgb(231,235,254);"
              >
                <img src="images/001-outsource.png" style="width:100%;" alt="" />
              </div>
              <div class="content ml-4">
                <h5 class="mb-2">
                  <a href="javascript:void(0)" class="text-dark"
                    >全日制劳务外包</a
                  >
                </h5>
                <p class="text-muted mb-4" style="font-size: 14px;">
                  依托于数字化灵活用工平台，为企业提供定制化人力资源外包服务，助力企业聚焦业务核心，提升企业管理效能.
                </p>
                <div>
                  <router-link
                    to="/helpcenter-support-request"
                    class="btn btn-sm btn-outline-primary"
                    >预约咨询
                    <arrow-right-icon class="fea icon-sm"></arrow-right-icon>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div
              class="media features p-4 bg-white shadow rounded align-items-center"
            >
              <div
                class="rounded-circle p-3"
                style="background-color: rgb(231,235,254);"
              >
                <img src="images/002-outsourcing.png" style="width:100%;" alt="" />
              </div>
              <div class="content ml-4">
                <h5 class="mb-2">
                  <a href="javascript:void(0)" class="text-dark"
                    >全日制劳务派遣</a
                  >
                </h5>
                <p class="text-muted mb-4" style="font-size: 14px;">
                  依托于数字化灵活用工平台，为企业提供定制化人力资源外包服务，助力企业聚焦业务核心，提升企业管理效能.
                </p>
                <div>
                  <router-link
                    to="/helpcenter-support-request"
                    class="btn btn-sm btn-outline-dark"
                    >预约咨询
                    <arrow-right-icon class="fea icon-sm"></arrow-right-icon>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
    </section>

    <section class="section bg-cta2" style="background: url('images/rencai-center-bg.png') center center;">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12">
                    <div class="section-title text-center mb-4">
                        <h4 class="title title-dark text-white mb-4">服务优势</h4>
                        <p class="para-dark mb-0 text-light">一手“包”办 “无编”也能无虑.</p>
                    </div>
                    <div class="row justify-content-center text-center">
                        <div class="col-md-2 col-6 mt-4 pt-2">
                            <div class="counter-box">
                                <img
                                    src="images/rencai12.png"
                                    class="img-fluid avatar avatar-lg-sm"
                                    alt=""
                                    />
              
                                <h6 class="counter-head title-dark text-light pt-2">快速招聘人才</h6>
                            </div>
                            <!--end counter box-->
                        </div>
                        <!--end col-->

                        <div class="col-md-2 col-6 mt-4 pt-2">
                            <div class="counter-box">
                                <img
                                    src="images/rencai13.png"
                                    class="img-fluid avatar avatar-lg-sm"
                                    alt=""
                                    />
              
                                <h6 class="counter-head title-dark text-light pt-2">优化合规用工</h6>
                            </div>
                            <!--end counter box-->
                        </div>
                        <!--end col-->

                        <div class="col-md-2 col-6 mt-4 pt-2">
                            <div class="counter-box">
                                <img
                                    src="images/rencai14.png"
                                    class="img-fluid avatar mx-auto"
                                    alt=""
                                    />
                        
                                <h6 class="counter-head title-dark text-light pt-2">降低用工风险</h6>
                            </div>
                            <!--end counter box-->
                        </div>
                        <!--end col-->

                        <div class="col-md-2 col-6 mt-4 pt-2">
                            <div class="counter-box">
                                <img
                                    src="images/rencai15.png"
                                    class="img-fluid avatar mx-auto"
                                    alt=""
                                    />
                    
                                <h6 class="counter-head title-dark text-light pt-2">灵活用工</h6>
                            </div>
                            <!--end counter box-->
                        </div>
                        <!--end col-->

                        <div class="col-md-2 col-6 mt-4 pt-2">
                            <div class="counter-box">
                                <img
                                    src="images/rencai16.png"
                                    class="img-fluid avatar mx-auto"
                                    alt=""
                                    />
                      
                                <h6 class="counter-head title-dark text-light pt-2">降低成本</h6>
                            </div>
                            <!--end counter box-->
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>

    <!-- <section class="section bg-light">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">服务优势</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                一手“包”办 “无编”也能无虑.
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mt-4">
            <carousel
              id="customer-testi"
              class="owl-carousel owl-theme"
              dir="ltr"
              :per-page="3"
            >
              <Slide>
                <div
                  class="card customer-testi text-center border-0 shadow rounded m-2"
                >
                  <div class="card-body">
                    <img
                      src="images/product/1.png"
                      class="img-fluid avatar mx-auto"
                      alt=""
                    />
                    <h6 class="text-primary mt-4">快速招聘人才</h6>
                    <p class="text-muted">
                      扁平式管理架构，极速响应，24小时快速响应客户需求
                    </p>
                  </div>
                </div>
              </Slide>
              <Slide>
                <div
                  class="card customer-testi text-center border-0 shadow rounded m-2"
                >
                  <div class="card-body">
                    <img
                      src="images/product/2.png"
                      class="img-fluid avatar mx-auto"
                      alt=""
                    />
                    <h6 class="text-primary mt-4">优化合规用工</h6>
                    <p class="text-muted">
                      帮助灵活用工企业合理合规解决薪酬结算、税务处理问题
                    </p>
                  </div>
                </div>
              </Slide>
              <Slide>
                <div
                  class="card customer-testi text-center border-0 shadow rounded m-2"
                >
                  <div class="card-body">
                    <img
                      src="images/product/3.png"
                      class="img-fluid avatar  mx-auto"
                      alt=""
                    />
                    <h6 class="text-primary mt-4">降低用工风险</h6>
                    <p class="text-muted">
                      劳动关系转移，全面介入项目风险把控与人员纠纷处理，分散用工风险
                    </p>
                  </div>
                </div>
              </Slide>
              <Slide>
                <div
                  class="card customer-testi text-center border-0 shadow rounded m-2"
                >
                  <div class="card-body">
                    <img
                      src="images/product/3.png"
                      class="img-fluid avatar  mx-auto"
                      alt=""
                    />
                    <h6 class="text-primary mt-4">灵活用工</h6>
                    <p class="text-muted">
                      突破人事编制，人岗匹配，提升内外部业务服务效率，保障业务发展
                    </p>
                  </div>
                </div>
              </Slide>
              <Slide>
                <div
                  class="card customer-testi text-center border-0 shadow rounded m-2"
                >
                  <div class="card-body">
                    <img
                      src="images/product/3.png"
                      class="img-fluid avatar  mx-auto"
                      alt=""
                    />
                    <h6 class="text-primary mt-4">降低成本</h6>
                    <p class="text-muted">
                      全生命周期管理，权责清晰，协助企业将员工劳动关系和用工管理剥离
                    </p>
                  </div>
                </div>
              </Slide>
            </carousel>
          </div>
        </div>
      </div>
    </section> -->
    <!--end section-->

    <section class="section bg-light">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">四大服务模块</h4>
            </div>
          </div>
          <!--end col-->
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <div
              class="card features fea-primary rounded p-4 position-relative overflow-hidden text-center"
            >
              <span class="h1 icon2 text-primary">
                <!-- <i class="uil uil-user-plus"></i> -->
                <img
                      src="images/rc1.png"
                      class="img-fluid avatar mx-auto"
                      style="height: 80px;"
                      alt=""
                    />
              </span>
              <div class="card-body p-0 content">
                <h5 class="mt-2">人员招募</h5>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div
              class="card features fea-primary rounded p-4 position-relative overflow-hidden text-center"
            >
              <span class="h1 icon2 text-primary">
                <img
                      src="images/rc2.png"
                      class="img-fluid avatar mx-auto"
                      style="height: 80px;"
                      alt=""
                    />
              </span>
              <div class="card-body p-0 content">
                <h5 class="mt-2">成本控制</h5>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
            <div
              class="card features fea-primary rounded p-4 position-relative overflow-hidden text-center"
            >
              <span class="h1 icon2 text-primary">
                <img
                      src="images/rc3.png"
                      class="img-fluid avatar mx-auto"
                      style="height: 80px;"
                      alt=""
                    />
              </span>
              <div class="card-body p-0 content">
                <h5 class="mt-2">风险管控</h5>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
            <div
              class="card features fea-primary rounded p-4 position-relative overflow-hidden text-center"
            >
              <span class="h1 icon2 text-primary">
                <img
                      src="images/rc4.png"
                      class="img-fluid avatar mx-auto"
                      style="height: 80px;"
                      alt=""
                    />
              </span>
              <div class="card-body p-0 content">
                <h5 class="mt-2">人事管理</h5>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
