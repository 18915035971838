<script>
import { ArrowUpIcon, ArrowRightIcon } from "vue-feather-icons";

import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";

import { Carousel, Slide } from "vue-carousel";

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";

/**
 * Page-aboutus-two component
 */
export default {
  data() {
    return {
      swiperOptions: {
        lazy: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    ArrowUpIcon,
    ArrowRightIcon,
    Swiper,
    SwiperSlide,
    Carousel,
    Slide,
  },
  directives: {
    swiper: directive,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  methods: {
    nextSlide() {
      this.$refs.mySwiper.$swiper.slideNext();
    },
    prevSlide() {
      this.$refs.mySwiper.$swiper.slidePrev();
    },
  },
};
</script>

<template>
  <div>
    <Navbar :nav-light="true" />

    <!-- Hero Start -->
    <section
      class="bg-half d-table w-100"
      style="background: url('images/company/banner2.jpg') center center;"
    >
      <!-- <div class="bg-overlay"></div> -->
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level title-heading">
              <h1 class="text-white title-dark">关于我们</h1>
              <!-- <p class="text-white-50 para-desc mb-0 mx-auto">开始与青桐盛夏合作，我们可以提供你所需的一切，以提高效率，建立联系.</p> -->
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item">
                      <router-link to="/">首页</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      关于我们
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!-- Hero End -->

    <section class="section">
      <div class="container">
        <div class="row align-items-center" id="counter">
          <div class="col-md-6">
            <img src="images/company/about2.png" class="img-fluid" alt="" />
          </div>
          <!--end col-->

          <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div class="ml-lg-4">
              <div class="d-flex mb-4">
                <span class="text-primary h1 mb-0"
                  ><span
                    class="counter-value display-1 font-weight-bold"
                    data-count="15"
                    >10</span
                  >+</span
                >
                <span class="h6 align-self-end ml-2"
                  >年 <br />
                  经验</span
                >
              </div>
              <div class="section-title">
                <h4 class="title mb-4">我们是谁 ?</h4>
                <p class="text-muted">
                  深圳青桐盛夏科技有限公司是专注于为高科技企业提供人才服务及用工成本优化服务的互联网科技企业，通过“科技+资源”的双驱动模式提升企业的招人用人效率，降低企业的用人成本 。目前，公司在全国已运营12家人力产业园，有和2000+全日制外包员工，服务的上市公司客户超20家。
                </p>
                <p class="text-muted">
                  青桐平台是深圳青桐盛夏科技有限公司旗下的互联网人力科技平台(http: //www. 91qt.com/) ， 专注于垂直化行业场景，运用互联网科技手段为企业提供人才招募、人才外 包、业务外包、工薪（服务费）结算、人力成本降低等全流程人力服务。
                </p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->

   <!-- ======= Portfolio Section ======= -->
    <section id="portfolio" class="portfolio">
      <div class="container">

        <div class="section-title" data-aos="fade-left">
          <h2>资质证书</h2>
          <p></p>
        </div>

        <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <img src="images/qt1.png" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4>劳务派遣经营许可证</h4>
                <p></p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-web">
            <div class="portfolio-wrap">
              <img src="images/qt4.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4>质量管理体系认证证书</h4>
                <p></p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <img src="images/qt3.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4>质量管理体系认证证书</h4>
              </div>
            </div>
          </div>

          <!-- <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <img src="images/qt2.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4>人力资源服务许可证</h4>
              </div>
            </div>
          </div> -->
        </div>

      </div>
    </section><!-- End Portfolio Section -->

    <!-- <section class="section bg-light">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="section-title text-center">
                        <h4 class="title mb-4">我们的愿景&价值观?</h4>
                        <p class="text-muted para-desc mx-auto mb-0">专注专业、务真务实、持续创新、共创共享；成为国内领先的新型人力资源科技服务商。</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mt-100 mt-60">
            <div class="row align-items-end mb-4 pb-4">
                <div class="col-md-8">
                    <div class="section-title text-center text-md-left">
                        <h4 class="title mb-4">团队风采</h4>
                        <p class="text-muted mb-0 para-desc">为了增强公司凝聚力，加强员工归属感，进一步推动公司企业文化建设.</p>
                    </div>
                </div>
            </div>

            <swiper ref="mySwiper" class="swiper-slider-hero position-relative d-block" style="height:45vh;" :options="swiperOptions">
                <swiper-slide>
                    <div class="swiper-slide d-flex align-items-center overflow-hidden">
                        <div class="slide-inner slide-bg-image d-flex align-items-center" :style="{'background':'url(' + require('../../public/images/corporate/1.jpg')+ ')'}">
                            <div class="bg-overlay"></div>
                            <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-12">
                                        <div class="title-heading text-center">
                                            <h1 class="heading text-white title-dark mb-4">公司年会</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="swiper-slide d-flex align-items-center overflow-hidden">
                        <div class="slide-inner slide-bg-image d-flex align-items-center" :style="{'background':'url(' + require('../../public/images/corporate/2.jpg')+ ')'}">
                            <div class="bg-overlay"></div>
                            <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-12">
                                        <div class="title-heading text-center">
                                            <h1 class="heading text-white title-dark mb-4">公司团建活动</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <div class="swiper-container" slot="pagination">
                    <div class="swiper-button-next border rounded-circle text-center" slot="button-prev" @click="nextSlide()"></div>
                    <div class="swiper-button-prev border rounded-circle text-center" slot="button-next" @click="prevSlide()"></div>
                </div>
            </swiper>
        </div>
    </section> -->

    <!--end section-->
    <!-- Team Start -->
    <!-- <section class="section bg-light">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">创使人团队</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                一个地方所聚集的
                <span class="text-primary font-weight-bold">团体</span>
                他们用创新和执行力撑起一个互联网梦想.
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div class="card text-center bg-transparent border-0">
              <div class="card-body p-0">
                <img
                  src="images/client/011.png"
                  class="img-fluid avatar avatar-ex-large rounded-circle"
                  alt=""
                />
                <div class="content pt-3 pb-3">
                  <h5 class="mb-0">
                    <a href="javascript:void(0)" class="name text-dark">汪锋</a>
                  </h5>
                  <small class="designation text-muted"
                    >C.E.O. 兰州大学硕士,
                    金蝶信息化专家，搜狐畅游总裁办主任，某税筹互联网公司联合创使人，人力资源&财税专家.</small
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div class="card text-center bg-transparent border-0">
              <div class="card-body p-0">
                <div class="position-relative">
                  <img
                    src="images/client/012.png"
                    class="img-fluid avatar avatar-ex-large rounded-circle"
                    alt=""
                  />
                </div>
                <div class="content pt-3 pb-3">
                  <h5 class="mb-0">
                    <a href="javascript:void(0)" class="name text-dark"
                      >柯硕灏</a
                    >
                  </h5>
                  <small class="designation text-muted"
                    >副总裁. 负责互联网产品及运营，曾任腾讯产品总监，美国北
                    卡罗莱纳州州立大学硕士。</small
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div class="card text-center bg-transparent border-0">
              <div class="card-body p-0">
                <div class="position-relative">
                  <img
                    src="images/client/013.png"
                    class="img-fluid avatar avatar-ex-large rounded-circle"
                    alt=""
                  />
                </div>
                <div class="content pt-3 pb-3">
                  <h5 class="mb-0">
                    <a href="javascript:void(0)" class="name text-dark"
                      >李鹏飞</a
                    >
                  </h5>
                  <small class="designation text-muted"
                    >副总裁. 负责技术研发与销售，中科大软件系统硕士，曾任腾
                    讯搜索联盟反作弊系统负责人，华泰证券第一代算法
                    交易跨平台技术负责人。</small
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div class="card text-center bg-transparent border-0">
              <div class="card-body p-0">
                <div class="position-relative">
                  <img
                    src="images/client/013.png"
                    class="img-fluid avatar avatar-ex-large rounded-circle"
                    alt=""
                  />
                </div>
                <div class="content pt-3 pb-3">
                  <h5 class="mb-0">
                    <a href="javascript:void(0)" class="name text-dark"
                      >陈久茂</a
                    >
                  </h5>
                  <small class="designation text-muted"
                    >副总裁. 负责公司业务运营，广东工业大学硕士，曾任欢聚
                    时代产品经理。</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end container-->

      <!-- <div class="container mt-100 mt-60">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="section-title">
                        <p class="text-muted para-desc mx-auto">开始与<span class="text-primary font-weight-bold">青桐盛夏</span> 合作，我们可以提供你所需的一切，以提高效率，建立联系.</p>
                    </div>
                </div>
            </div>
        </div> -->
      <!--end container-->
    <!-- </section> -->
    <!--end section-->
    <!-- Team End -->
    <section
      class="section bg-light"
      style="background: url('images/rencai-center-bg.png') center center;"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9">
            <carousel
              id="single-owl"
              class="owl-carousel owl-theme"
              dir="ltr"
              :per-page="1"
              :autoplay="true"
              :loop="true"
            >
              <Slide>
                <div class="testi text-center">
                  <div class="commenter">
                    <div class="content">
                      <h2 class="text-white">我们的愿景</h2>
                    </div>
                  </div>
                  <p class="h5 text-white font-italic font-weight-normal mt-4">
                    国内领先的新型人力资源科技服务商
                  </p>
                </div>
                <!--end review content-->
              </Slide>
              <Slide>
                <div class="testi text-center">
                  <div class="commenter">
                    <div class="content">
                      <h2 class="text-white">我们的价值观</h2>
                    </div>
                  </div>
                  <p class="h5 text-white font-italic font-weight-normal mt-4">
                    专注专业、务真务实、持续创新、共创共享
                  </p>
                </div>
                <!--end review content-->
              </Slide>
            </carousel>
            <!--end owl carousel-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>

    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

<style>
.modal-content {
  background-color: transparent;
}
.swiper-button-prev::after {
  display: none;
}

.swiper-button-next::after {
  display: none;
}
/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 20px auto;
  list-style: none;
  text-align: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
}

.portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
  color: #fff;
  background: #3b4ef8;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(45, 64, 95, 0.6);
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(45, 64, 95, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap img {
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  font-style: italic;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  margin: 0 5px 0 0;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #9da7fc;
}

.portfolio .portfolio-wrap:hover::before {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover img {
  transform: scale(1.2);
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #3b4ef8;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #3b4ef8;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(45, 64, 95, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}
</style>
