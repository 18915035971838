<script>
import {
  ArrowUpIcon,
  HelpCircleIcon,
  ChevronRightIcon,
  SmartphoneIcon,
} from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";

import { VueTyper } from "vue-typer";

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";

/**
 * Index-digital-agency component
 */
export default {
  data() {
    return {};
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    Carousel,
    Slide,
    ArrowUpIcon,
    HelpCircleIcon,
    ChevronRightIcon,
    SmartphoneIcon,
    "vue-typer": VueTyper,
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section
      class="bg-half-260 d-table w-100"
      style="background: url('images/digital/home-bg.png') center center"
      id="home"
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6">
            <div class="title-heading mt-4">
              <h1 class="heading mb-3 text-primary">
                <br />自由职业者
                <vue-typer :text="['外包', '薪税']"></vue-typer>
              </h1>
              <!-- <p class="para-desc text-muted">青桐灵活用工平台将劳动关系和劳务关系转变为合作关系，同时将工资薪金所得和劳务报酬所得转变为个人经营收入。通过核定征收，进一步降低税负率.</p> -->
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <img src="images/digital/seo.gif" class="img-fluid" alt="" />
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Partners start -->
    <!-- <section class="py-4 border-bottom border-top">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-2 col-md-2 col-6 text-center py-4">
                    <img src="images/client/amazon.svg" class="avatar avatar-ex-sm" alt="">
                </div>

                <div class="col-lg-2 col-md-2 col-6 text-center py-4">
                    <img src="images/client/google.svg" class="avatar avatar-ex-sm" alt="">
                </div>

                <div class="col-lg-2 col-md-2 col-6 text-center py-4">
                    <img src="images/client/lenovo.svg" class="avatar avatar-ex-sm" alt="">
                </div>

                <div class="col-lg-2 col-md-2 col-6 text-center py-4">
                    <img src="images/client/paypal.svg" class="avatar avatar-ex-sm" alt="">
                </div>

                <div class="col-lg-2 col-md-2 col-6 text-center py-4">
                    <img src="images/client/shopify.svg" class="avatar avatar-ex-sm" alt="">
                </div>

                <div class="col-lg-2 col-md-2 col-6 text-center py-4">
                    <img src="images/client/spotify.svg" class="avatar avatar-ex-sm" alt="">
                </div>
            </div>
        </div>
    </section> -->
    <!--end section-->
    <!-- Partners End -->
    <!-- Testi Start -->
    <section
      class="section bg-light"
      style=""
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9">
            <carousel
              id="single-owl"
              class="owl-carousel owl-theme"
              dir="ltr"
              :per-page="1"
              :autoplay="true"
              :loop="true"
            >
              <Slide>
                <div class="testi text-center">
                  <div class="commenter">
                    <div class="content">
                      <h3 class="text-primary">共享经济平台核心特征</h3>
                    </div>
                  </div>
                  <p class="h5 text-muted font-italic font-weight-normal mt-4">
                    "
                    提供在线平台生态，让生产者和消费者参加到平台中：需要大量自由职业者参与到生态平台；增值税征收抵扣链条断裂，实际税负偏离；不提供生产工具；
                    不保证自由职业者的收入稳定. "
                  </p>
                </div>
                <!--end review content-->
              </Slide>
              <Slide>
                <div class="testi text-center">
                  <div class="commenter">
                    <div class="content">
                      <h3 class="text-primary">自由职业者核心特征</h3>
                    </div>
                  </div>
                  <p class="h5 text-muted font-italic font-weight-normal mt-4">
                    "
                    负盈亏/自担风险收入不稳定：与平台为合作分成关系，收入不稳定，承担经营风险；工具成本费用自担，按照劳务所得交税实际税负偏高；分散在全国个体，多通过在线完成交易，难以开具增值税发票给平台.
                    "
                  </p>
                </div>
                <!--end review content-->
              </Slide>
            </carousel>
            <!--end owl carousel-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Testi End -->

    <!-- Services Start -->
    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">服务对象</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                多业务模块组合，服务场景支持更灵活.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
            <div class="features text-center pt-3 pb-3">
              <img src="images/icon/wifi.svg" height="50" alt="" />
              <div class="content mt-3">
                <h4 class="title-2">网络推广</h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
            <div class="features text-center pt-3 pb-3">
              <img src="images/icon/printer.svg" height="50" alt="" />
              <div class="content mt-3">
                <h4 class="title-2">在互讲师</h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
            <div class="features text-center pt-3 pb-3">
              <img src="images/icon/24-hours.svg" height="50" alt="" />
              <div class="content mt-3">
                <h4 class="title-2">网络主播</h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
            <div class="features text-center pt-3 pb-3">
              <img src="images/icon/serving-dish.svg" height="50" alt="" />
              <div class="content mt-3">
                <h4 class="title-2">外卖小哥</h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
            <div class="features text-center pt-3 pb-3">
              <img src="images/icon/mail2.svg" height="50" alt="" />
              <div class="content mt-3">
                <h4 class="title-2">在线设计师</h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
            <div class="features text-center pt-3 pb-3">
              <img src="images/icon/calendar_b.svg" height="50" alt="" />
              <div class="content mt-3">
                <h4 class="title-2">专车 & 顺风车</h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
            <div class="features text-center pt-3 pb-3">
              <img src="images/icon/coffee-cup.svg" height="50" alt="" />
              <div class="content mt-3">
                <h4 class="title-2">在线医疗</h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
            <div class="features text-center pt-3 pb-3">
              <img src="images/icon/question.svg" height="50" alt="" />
              <div class="content mt-3">
                <h4 class="title-2">其他行业</h4>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Services End -->

    <section class="section pt-0">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-5 col-12 mr-4">
            <div class="row align-items-center">
              <div class="col-lg-10 col-12">
                <div class="p-0">
                  <img
                    src="images/illustrator/Marketing_strategy_SVG.svg"
                    class="img-fluid mx-auto d-block"
                    alt="work-image"
                  />
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end col-->

          <div class="col-lg-6 col-md-6 mt-4 mt-lg-0 pt-lg-0">
            <div class="ml-lg-4">
              <div class="section-title mb-4 pb-2">
                <h4 class="title mb-4">解决平台/自由职业者痛点</h4>
                <p class="text-muted para-desc mb-0">
                  赶快联系我们，我们将会以安全合规、有据可循为基本原则，解决自由职业者与雇主之间对接难成本高的痛点难题，您的问题让我们来解决.
                </p>
              </div>

              <ul class="list-unstyled text-muted">
                <li class="mb-0">
                  <span class="text-primary h4 mr-2"
                    ><i class="uim uim-check-circle"></i></span
                  >自由职业者代开发票难的问题
                </li>
                <li class="mb-0">
                  <span class="text-primary h4 mr-2"
                    ><i class="uim uim-check-circle"></i></span
                  >平台进项难/增值税率高的问题
                </li>
                <li class="mb-0">
                  <span class="text-primary h4 mr-2"
                    ><i class="uim uim-check-circle"></i></span
                  >合法合规大幅度降低自由职业者个税
                </li>
                <li class="mb-0">
                  <span class="text-primary h4 mr-2"
                    ><i class="uim uim-check-circle"></i></span
                  >互联网平台用工存在合规风险
                </li>
                <li class="mb-0">
                  <span class="text-primary h4 mr-2"
                    ><i class="uim uim-check-circle"></i></span
                  >个人，劳务用工无法取得发票
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!--end row-->
      </div>
      <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">我们的优势 ?</h4>
              <p class="text-muted para-desc mb-0 mx-auto">
                我们拥有“互联网+灵活用工”领域的灵活用工撮合、发薪、节税一站式服务平台，致力于为企业、从业者以及人力资源公司提供高效实时信息交互服务，搭配电子签约、税务代缴、实时分账系统实现远程签约、一键完税、实时发薪.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-lg-3 col-md-4 mt-4 pt-2">
            <div
              class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
            >
              <span class="h1 icon2 text-primary">
                <img src="images/zy1.png" alt="" style="height: 60px;">
              </span>
              <div class="card-body p-0 content">
                <h5>专业高效</h5>
                <p class="para text-muted mb-0">
                  1 V 1 专业顾问，为企业提供场景化解决方案.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 mt-4 pt-2">
            <div
              class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
            >
              <span class="h1 icon2 text-primary">
                <img src="images/zy2.png" alt="" style="height: 60px;">
              </span>
              <div class="card-body p-0 content">
                <h5>合法合规</h5>
                <p class="para text-muted mb-0">
                  真实业务留存实名认证，依法纳税.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 mt-4 pt-2">
            <div
              class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
            >
              <span class="h1 icon2 text-primary">
                <img src="images/zy3.png" alt="" style="height: 60px;">
              </span>
              <div class="card-body p-0 content">
                <h5>专业团队</h5>
                <p class="para text-muted mb-0">
                  致力于行业多年，积累大量经验.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 mt-4 pt-2">
            <div
              class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
            >
              <span class="h1 icon2 text-primary">
                <img src="images/zy4.png" alt="" style="height: 60px;">
              </span>
              <div class="card-body p-0 content">
                <h5>定制方案</h5>
                <p class="para text-muted mb-0">
                  从客户实际出发，定制客户专属解决方案.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
    </section>
    <!--end section-->
    <!-- <div class="position-relative">
        <div class="shape overflow-hidden text-light">
            <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
            </svg>
        </div>
    </div> -->
    <!-- End -->

    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

<style>
.modal-content {
  background-color: transparent;
}
</style>
