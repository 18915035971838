import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/index')
  },
  {
    path: '/index-landing-three',
    name: 'landing-three',
    component: () => import('../views/index-landing-three')
  },
  {
    path: '/index-marketing',
    name: 'marketing',
    component: () => import('../views/index-marketing')
  },
  {
    path: '/index-insurance',
    name: 'insurance',
    component: () => import('../views/index-insurance')
  },
  {
    path: '/index-digital-agency',
    name: 'digital-agency',
    component: () => import('../views/index-digital-agency')
  },
  {
    path: '/page-xuetang',
    name: 'xuetang',
    component: () => import('../views/page-xuetang')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/test')
  },
  {
    path: '/page-aboutus-two',
    name: 'aboutus-two',
    component: () => import('../views/page-aboutus-two')
  },
  {
    path: '/page-blog-detail-two',
    name: 'blog-detail-two',
    component: () => import('../views/page-blog-detail-two')
  },
  {
    path: '/page-blog-list',
    name: 'blog-list',
    component: () => import('../views/page-blog-list')
  },
  {
    path: '/helpcenter-support-request',
    name: 'helpcenter-support-request',
    component: () => import('../views/helpcenter-support-request')
  },
  {
    path: '/components',
    name: 'components',
    component: () => import('../views/components')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
