<script>
/**
 * Navbar component
 */
export default {
    data() {
        return {
            isCondensed: false
        }
    },
    props: {
        isWhiteNavbar: {
            type: Boolean
        },
        navLight: {
            type: Boolean
        }
    },

    mounted: () => {
        window.onscroll = function () {
            onwindowScroll();
        };

        function onwindowScroll() {
            if (
                document.body.scrollTop > 50 ||
                document.documentElement.scrollTop > 50
            ) {
                document.getElementById("topnav").classList.add("nav-sticky");
            } else {
                document.getElementById("topnav").classList.remove("nav-sticky");
            }

            if (document.body.scrollTop > 100 ||
                document.documentElement.scrollTop > 100) {
                document.getElementById('back-to-top').style.display = 'inline';
            } else {
                document.getElementById('back-to-top').style.display = 'none';
            }
        }

        var links = document.getElementsByClassName("side-nav-link-ref");
        var matchingMenuItem = null;
        for (var i = 0; i < links.length; i++) {
            if (window.location.pathname === links[i].pathname) {
                matchingMenuItem = links[i];
                break;
            }
        }

        if (matchingMenuItem) {
            matchingMenuItem.classList.add("active");
            var parent = matchingMenuItem.parentElement;

            /**
             * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
             * We should come up with non hard coded approach
             */
            if (parent) {
                parent.classList.add("active");
                const parent2 = parent.parentElement;
                if (parent2) {
                    parent2.classList.add("active");
                    const parent3 = parent2.parentElement;
                    if (parent3) {
                        parent3.classList.add("active");
                        const parent4 = parent3.parentElement;
                        if (parent4) {
                            const parent5 = parent4.parentElement;
                            parent5.classList.add("active");
                        }
                    }
                }
            }
        }
    },
    methods: {
        /**
         * Toggle menu
         */
        toggleMenu() {
            this.isCondensed = !this.isCondensed;
            if (this.isCondensed) {
                document.getElementById("navigation").style.display = "block";
            } else
                document.getElementById("navigation").style.display = "none";
        },

        /**
         * Menu clicked show the submenu
         */
        onMenuClick(event) {
            event.preventDefault();
            const nextEl = event.target.nextSibling.nextSibling;
            console.log(event.target.nextSibling.nextSibling)
            console.log(event.target.lastChild)
            console.log(event.target.childNodes)

            if (nextEl && !nextEl.classList.contains("open")) {
                const parentEl = event.target.parentNode;
                if (parentEl) {
                    parentEl.classList.remove("open");
                }
                nextEl.classList.add("open");
            } else if (nextEl) {
                nextEl.classList.remove("open");
            }
            return false;
        },
    }
}
</script>

<template>
<div>
    <!-- Navbar STart -->
    <header id="topnav" class="defaultscroll sticky" >
        <div class="container" :class="{'bg-white': isWhiteNavbar === true}">
            <!-- Logo container-->
            <div>
                <router-link class="logo" to="/" v-if="navLight !== true">
                    <img src="images/logo-dark.png" height="26" alt="">
                </router-link>
                <router-link class="logo" to="/" v-else>
                    <img src="images/logo-dark.png" class="l-dark" height="26" alt="">
                    <img src="images/logo-light.png" class="l-light" height="26" alt="">
                </router-link>
            </div>
            <!-- <div class="buy-button">
                <a href="#" class="btn btn-sm" :class="{'btn-light': navLight === true, 'btn-primary':  navLight !== true}">登录</a>
            </div> -->
            <!--end login button-->
            <!-- End Logo container-->
            <div class="menu-extras">
                <div class="menu-item">
                    <!-- Mobile menu toggle-->
                    <a class="navbar-toggle" @click="toggleMenu()" :class="{'open': isCondensed === true}">
                        <div class="lines">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </a>
                    <!-- End mobile menu toggle-->
                </div>
            </div>

            <div id="navigation">
                <!-- Navigation Menu-->
                <ul class="navigation-menu" :class="{'nav-light': navLight === true}">
                    <li>
                        <router-link to="/" class="side-nav-link-ref">首页</router-link>
                    </li>
                    <!-- <li>
                        <router-link to="/" class="side-nav-link-ref">产品及服务</router-link>
                    </li> -->
                    <li class="has-submenu">
                        <a href="javascript:void(0)" @click="onMenuClick">产品及服务</a><span class="menu-arrow"></span>
                        <ul class="submenu">
                            <li>
                                <router-link to="/index-landing-three" class="side-nav-link-ref">招聘外包</router-link>
                            </li>
                            <li>
                                <router-link to="/index-insurance" class="side-nav-link-ref">人才外包</router-link>
                            </li>
                            <li>
                                <router-link to="/index-marketing" class="side-nav-link-ref">业务外包</router-link>
                            </li>
                            <li>
                                <router-link to="/index-digital-agency" class="side-nav-link-ref">自由职业者工作室</router-link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <router-link to="/page-xuetang" target="_blank" class="side-nav-link-ref">青桐学堂</router-link>
                    </li>
                    <li>
                        <router-link to="/page-aboutus-two" class="side-nav-link-ref">关于我们</router-link>
                    </li>
                    <!-- <li>
                        <router-link to="/" class="side-nav-link-ref">外包客户门户</router-link>
                    </li> -->
                    <li>
                        <router-link to="/page-blog-list" class="side-nav-link-ref">新闻资讯</router-link>
                    </li>
                    <li>
                        <router-link to="/helpcenter-support-request" class="side-nav-link-ref">联系我们</router-link>
                    </li>
                    <!-- <li class="has-submenu">
                        <a href="javascript:void(0)" @click="onMenuClick">Landing</a><span class="menu-arrow"></span>
                        <ul class="submenu megamenu">
                            <li>
                                <ul>
                                    <li>
                                        <router-link to="/index-saas" class="side-nav-link-ref">Saas</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-classic-saas" class="side-nav-link-ref">Classic Saas</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-agency" class="side-nav-link-ref">Agency</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-apps" class="side-nav-link-ref">Application</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-classic-app" class="side-nav-link-ref">Classic Application</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-studio" class="side-nav-link-ref">Studio</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-business" class="side-nav-link-ref">Business</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-modern-business" class="side-nav-link-ref">Modern Business</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-hotel" class="side-nav-link-ref">Hotel</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-marketing" class="side-nav-link-ref">Marketing</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-enterprise" class="side-nav-link-ref">Enterprise</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-insurance" class="side-nav-link-ref">Insurance</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-shop" class="side-nav-link-ref">Shop</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-coworking" class="side-nav-link-ref">Coworking</router-link>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <ul>
                                    <li>
                                        <router-link to="/index-it-solution" class="side-nav-link-ref">IT Solution <span class="badge badge-danger rounded">v2.5</span></router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-corporate" class="side-nav-link-ref">Corporate Business<span class="badge badge-danger rounded">v2.5</span></router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-task-management" class="side-nav-link-ref">Task Management <span class="badge badge-danger rounded">v2.5</span></router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-email-inbox" class="side-nav-link-ref">Email Inbox <span class="badge badge-danger rounded">v2.5</span></router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-landing-one" class="side-nav-link-ref">Landing One <span class="badge badge-danger rounded">v2.5</span></router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-landing-two" class="side-nav-link-ref">Landing Two <span class="badge badge-danger rounded">v2.5</span></router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-landing-three" class="side-nav-link-ref">Landing Three <span class="badge badge-danger rounded">v2.5</span></router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-travel" class="side-nav-link-ref">Travel <span class="badge badge-danger rounded">v2.5</span></router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-blog" class="side-nav-link-ref">Blog <span class="badge badge-danger rounded">v2.5</span></router-link>
                                    </li>
                                    <li>
                                        <router-link to="/forums" class="side-nav-link-ref">Forums <span class="badge badge-danger rounded">v2.5</span></router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-personal" class="side-nav-link-ref">Personal</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-services" class="side-nav-link-ref">Service</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-payments" class="side-nav-link-ref">Payments</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-crypto" class="side-nav-link-ref">Cryptocurrency</router-link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li>
                                        <router-link to="/index-course" class="side-nav-link-ref">Course</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-online-learning" class="side-nav-link-ref">Online Learning</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-hosting" class="side-nav-link-ref">Hosting & Domain</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-event" class="side-nav-link-ref">Event</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-single-product" class="side-nav-link-ref">Product</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-portfolio" class="side-nav-link-ref">Portfolio</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-job" class="side-nav-link-ref">Job</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-social-marketing" class="side-nav-link-ref">Social Media</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-digital-agency" class="side-nav-link-ref">Digital Agency</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-car-riding" class="side-nav-link-ref">Car Ride</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-customer" class="side-nav-link-ref">Customer</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-software" class="side-nav-link-ref">Software</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-ebook" class="side-nav-link-ref">E-Book</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/index-onepage" class="side-nav-link-ref">Saas <span class="badge badge-pill badge-warning ml-2">Onepage</span></router-link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li> -->
                    <!-- <li class="has-submenu">
                        <a href="javascript:void(0)" @click="onMenuClick">Docs</a><span class="menu-arrow"></span>
                        <ul class="submenu">
                            <li>
                                <router-link to="/documentation" class="side-nav-link-ref">Documentation</router-link>
                            </li>
                            <li>
                                <router-link to="/changelog" class="side-nav-link-ref">Changelog</router-link>
                            </li>
                            <li>
                                <router-link to="/components" class="side-nav-link-ref">Components</router-link>
                            </li>
                            <li>
                                <router-link to="/widget" class="side-nav-link-ref">Widget</router-link>
                            </li>
                        </ul>
                    </li> -->
                </ul>
                <!--end navigation menu-->
                <!-- <div class="buy-menu-btn d-none">
                    <a href="#" class="btn btn-primary">Buy Now</a>
                </div> -->
                <!--end login button-->
            </div>
            <!--end navigation-->
        </div>
        <!--end container-->
    </header>
    <!--end header-->
    <!-- Navbar End -->
</div>
</template>

<style scoped>
.bg-white {
    border-radius: 3px;
}
</style>
