<script>
import { VueTyper } from "vue-typer";

import countTo from "vue-count-to";
import {
  ArrowUpIcon,
  UserIcon,
  MailIcon,
  PhoneIcon,
  PlayIcon,
} from "vue-feather-icons";

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
/**
 * Index-coworking component
 */
export default {
  data() {
    return {
      scrollShow: true,
      show1: false,
      show2: false,
      slide: 0,
      caseList: [
        {
          image: "images/work/1.jpg",
          list: [
            {
              title: "心态问题",
              text: "抱怨和借口一大堆，缺乏向内思考",
            },
            {
              title: "安于现状",
              text: "抱怨和借口一大堆，缺乏向内思考",
            },
            {
              title: "领导力缺乏",
              text: "抱怨和借口一大堆，缺乏向内思考",
            },
            {
              title: "领导力问题",
              text: "抱怨和借口一大堆，缺乏向内思考",
            },
          ],
          variant: "primary",
          type: "领导力问题",
        },
        {
          image: "images/work/2.jpg",
          list: [
            {
              title: "缺乏战略",
              text: "缺乏公司战略和系统电目标，想到什么做什么，到头来效果不理想",
            },
            {
              title: "缺乏计划",
              text: "有目标无计划或计划缺乏有效性，枪声一响计划作废一半",
            },
            {
              title: "缺乏预算",
              text: "财务/成本/费用无预算控制，没关注投入产出比",
            },
          ],
          variant: "warning",
          type: "目标问题",
        },
        {
          image: "images/work/3.jpg",
          list: [
            {
              title: "缺乏团队力量",
              text: "没有形成整体力量，个人天天加班，四处救火，同样的问题重复发生",
            },
            {
              title: "岗位职责不明确",
              text: "组织架构不清晰，合适人才没有发展最大效能，内部不能有效协同",
            },
            {
              title: "缺乏人才培养计划",
              text: "人才梯队无培养，得力干将缺乏，凡事自己上",
            },
          ],
          variant: "success",
          type: "团队问题",
        },
        {
          image: "images/work/4.jpg",
          list: [
            {
              title: "缺乏流程工具化",
              text: "没有自作手册，缺乏指导书、模版、检查清单、案例库，换人换个做法",
            },
            {
              title: "缺乏过程控制机制",
              text: "过程缺乏控制机制，目标过程两张皮，任其发展，秋后算账又头疼",
            },
            {
              title: "缺乏有效考核手段",
              text: "缺乏一套有针对性的考核奖励机制",
            },
          ],
          variant: "info",
          type: "机制问题",
        },
        {
          image: "images/work/5.jpg",
          list: [
            {
              title: "缺乏对标",
              text: "对外不对标，自我感觉良好，缺乏参照对手",
            },
            {
              title: "缺乏落实",
              text: "参加了培训，学习了工具和方法，但回到工作中就忘记，还是不会做",
            },
            {
              title: "缺乏复盘",
              text: "对内缺乏复盘和改进，优秀经验缺乏复制，被同一个石头砸两次",
            },
          ],
          variant: "primary",
          type: "学习问题",
        },
      ],
      filterCategory: "all",
      visible: false,
    };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    ArrowUpIcon,
    UserIcon,
    MailIcon,
    PhoneIcon,
    PlayIcon,
    PlayIcon,
    "vue-typer": VueTyper,
    countTo,
  },
  computed: {
    filteredData: function () {
      if (this.filterCategory === "all") {
        return this.caseList;
      } else {
        return this.caseList.filter((x) => x.type === this.filterCategory);
      }
    },
  },
  methods: {
    updateFilter(filterName) {
      this.filterCategory = filterName;
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    showKf: function (e) {
      // this.show1 = !this.show1;
      console.log(e);
      if (e == 1) {
        this.show1 = !this.show1;
        this.show2 = false;
      }
      if (e == 2) {
        this.show2 = !this.show2;
        this.show1 = false;
      }
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      console.log("滚动距离", scrollTop);
      if (scrollTop > 600) {
        this.scrollShow = false;
      } else {
        this.scrollShow = true;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<template>
  <div>
    <Navbar :nav-light="false" :isWhiteNavbar="true" />
    <section
      class="bg-half d-table w-100"
      style="
        background: url('images/marketing/marketing2.png') center center;
        background-size: cover;
        height: 500px;
      "
    >
      <!-- <div class="bg-overlay"></div> -->
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level title-heading">
              <!-- <h1 class="text-white">青桐学堂</h1> -->
              <!-- <p class="text-white-50 para-desc mb-0 mx-auto">开始与青桐盛夏合作，我们可以提供你所需的一切，以提高效率，建立联系.</p> -->
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!-- Hero End -->
    <section
      class="bg-half border-bottom d-table w-100"
      id="home"
      style="padding: 3.3rem 0"
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-7">
            <div class="title-heading mt-4">
              <!-- <div class="alert alert-light alert-pills shadow" role="alert">
                            <span class="badge badge-pill badge-danger mr-1">v2.5</span>
                            <span class="content"> Build <span class="text-primary">anything</span> you want - Landrick.</span>
                        </div> -->
              <h1 class="heading mb-3" style="color: #39e09d">
                青桐学堂
                <span class="element text-primary">
                  <vue-typer
                    v-if="scrollShow"
                    :text="[
                      '助力人才和企业的持续成长',
                      '为企业提供一流的培训解决方案',
                      '为个人崛起与组织的持续发展助力',
                    ]"
                    :pre-type-delay="200"
                    :type-delay="200"
                  ></vue-typer>
                  <span v-else style="color: #161c2d">
                    为企业提供一流的培训解决方案
                  </span>
                </span>
              </h1>
              <!-- <p class="para-desc text-muted">企业在完成初创期之后，会经历三次管理整合阶段,对发展过程中产生、积累各种风险及其背后的管理问题进行管理整合——组织架构、文化、流程、体制、机制等的调整变革，以及团队建设、人才培养、领导力提升等。优秀企业边“成长”边“整合”，成长曲线和整合曲线并行，即“边开车，边修车”</p> -->
              <!-- <div class="mt-4">
                    <p class="para-desc para-dark mx-auto text-muted text-left">
                      第一次整合：<span class="text-success"
                        >以组织能力建设为主题</span
                      >
                    </p>
                    <p class="para-desc para-dark mx-auto text-muted text-left">
                      第二次整合：<span class="text-success"
                        >以体制变革为主题</span
                      >
                    </p>
                    <p class="para-desc para-dark mx-auto text-muted text-left">
                      第三次整合：<span class="text-success"
                        >以组织范式转换为主题</span
                      >
                    </p>
                  </div> -->
              <!-- <p class="para-desc para-dark mx-auto text-muted text-left">
                    不论是处于在快速发展期的小型企业或是未来计划上市的中型企业，还是已上市需转型的中大型企业，在面对不确定性和行业竞争加剧的时代，都需要不断提升对人才和组织的管理水平才能实现有效增长、长期可持续成长
                  </p> -->
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div class="position-relative">
              <img
                src="images/digital/seo.gif"
                class="rounded img-fluid mx-auto d-block"
                alt=""
              />
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>

    <section class="section" style="padding-top: 3rem">
      <div class="container mt-0">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">青桐培训价值观</h4>
              <p class="text-muted para-desc mb-0 mx-auto">
                通过“训前-训中-训后”全程管理模式以及线上线下融合的方式打通学习价值链，领先的学习内容，创新的学习模式，优秀的教师资源，领先的最佳实践，难忘的学习体验，全面的培训服务
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row align-items-center">
          <div class="col-md-3 col-12 mt-4 pt-2">
            <div
              class="card pricing-rates best-plan rounded bg-light text-center border-0"
            >
              <div class="card-body py-5">
                <img
                  src="images/icon/lightbulb.svg"
                  class="mb-4"
                  height="50"
                  alt=""
                />
                <h2 class="mb-4 text-success">体系化</h2>
                <div class="d-flex justify-content-center mb-4">
                  <span class="h4 mb-0 mt-2 text-warning">无体系，不学习</span>
                  <!-- <span class="price h1 mb-0">19</span> -->
                  <!-- <span class="h4 align-self-end mb-1">/mo</span> -->
                </div>

                <p class="text-muted">
                  所有课程设计均有系统化的设计，坚决不做碎片化的搬运工
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-3 col-12 mt-4 pt-2">
            <div
              class="card pricing-rates best-plan rounded bg-light text-center border-0"
            >
              <!-- <div class="lable text-center bg-primary pt-2 pb-2">
                <h6 class="best text-white mb-0 text-uppercase">Best</h6>
              </div> -->
              <div class="card-body py-5">
                <img
                  src="images/icon/table.svg"
                  class="mb-4"
                  height="50"
                  alt=""
                />
                <h2 class="mb-4 text-success">定制化</h2>
                <div class="d-flex justify-content-center mb-4">
                  <span class="h4 mb-0 mt-2 text-warning">无定制，不方案</span>
                  <!-- <span class="price h1 mb-0">不方案</span> -->
                  <!-- <span class="h4 align-self-end mb-1">不方案</span> -->
                </div>

                <p class="text-muted">
                  以企业或个人具体的需求出发，定制设计个性化的解决方案
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-3 col-12 mt-4 pt-2">
            <div
              class="card pricing-rates best-plan rounded bg-light text-center border-0"
            >
              <div class="card-body py-5">
                <img
                  src="images/icon/star.svg"
                  class="mb-4"
                  height="50"
                  alt=""
                />
                <h2 class="mb-4 text-success">实战化</h2>
                <div class="d-flex justify-content-center mb-4">
                  <span class="h4 mb-0 mt-2 text-warning">无实战，不内容</span>
                </div>

                <p class="text-muted">
                  无论是老师选拔还是课程设计，均从实战中来，到实战中去
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-3 col-12 mt-4 pt-2">
            <div
              class="card pricing-rates best-plan rounded bg-light text-center border-0"
            >
              <div class="card-body py-5">
                <img
                  src="images/icon/start-up.svg"
                  class="mb-4"
                  height="50"
                  alt=""
                />
                <h2 class="mb-4 text-success">落地化</h2>
                <div class="d-flex justify-content-center mb-4">
                  <span class="h4 mb-0 mt-2 text-warning">无落地，不培训</span>
                </div>

                <p class="text-muted">
                  课程设计以解决问题为起点，坚决坚持学以致用，用以促学
                </p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
    </section>

    <!-- Partners start -->
    <section class="py-4 border-bottom border-top">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <h5>中小企业成长普遍五大问题</h5>
            <p class="text-muted text-info font-weight-bold">
              企业成长不怕慢，就怕站
            </p>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row projects-wrapper">
          <div
            class="col-lg-4 col-md-6 col-12 mt-4 pt-2 business"
            v-for="(item, index) in filteredData"
            :key="index"
          >
            <div
              class="card blog border-0 work-container work-classic shadow rounded-md overflow-hidden"
            >
              <div class="card-body">
                <div class="content">
                  <a
                    href="javascript:void(0)"
                    :class="`badge badge-${item.variant}`"
                    >{{ item.type }}</a
                  >
                  <ul class="mt-3">
                    <li
                      v-for="(item2, index) in item.list"
                      :key="index"
                      class="mt-2"
                    >
                      <h6 style="font-size: 14px; line-height: 14px">
                        {{ item2.title }}
                      </h6>
                      <span class="text-muted" style="font-size: 12px">{{
                        item2.text
                      }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 text-center">
                <div class="section-title mb-4">
                  <h4 class="title mb-4 mt-4">
                    如果你是他们的一员，对当下不满，对未来渴望，或许考虑马上学习一下
                  </h4>
                  <ul class="row justify-content-center list-unstyled">
                    <li class="list-inline-item">
                      <span class="text-muted font-weight-bold"
                        >1.企业高管</span
                      >
                    </li>
                    <li class="list-inline-item ml-3">
                      <span class="text-muted font-weight-bold"
                        >2.中层管理者</span
                      >
                    </li>
                    <li class="list-inline-item ml-3">
                      <span class="text-muted font-weight-bold"
                        >3.部门经理</span
                      >
                    </li>
                    <li class="list-inline-item ml-3">
                      <span class="text-muted font-weight-bold"
                        >4.业务主管</span
                      >
                    </li>
                    <li class="list-inline-item ml-3">
                      <span class="text-muted font-weight-bold"
                        >5.项目经理</span
                      >
                    </li>
                    <li class="list-inline-item ml-3">
                      <span class="text-muted font-weight-bold"
                        >6.项目主管</span
                      >
                    </li>
                    <li class="list-inline-item ml-3">
                      <span class="text-muted font-weight-bold"
                        >7.项目团队成员</span
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <!--end col-->
            </div>
          </div>
        </div>
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Partners End -->

    <!-- About Start -->
    <section class="section" style="margin-top: -1rem">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-6 col-12">
            <img
              src="images/coworking/about.jpg"
              class="img-fluid rounded"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="col-lg-7 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div class="section-title ml-lg-4">
              <h4 class="title mb-4">如何快速成长</h4>
              <p class="mb-2 font-weight-bold text-success">
                要么抄，要么把复杂、陌生的大问题分解转化为简单熟悉的小问题再抄
              </p>
              <p class="mb-2 font-weight-bold">
                “站在巨人的肩膀上”，对比打胜仗最直接的方法+打过胜仗的人，找到差距形成行动计划
              </p>
              <a
                href="javascript:void(0)"
                class="btn btn-soft-success btn-md mt-2"
                >对标的学习方法</a
              >
              <ol class="mt-3" style="padding-left: 16px">
                <li class="mt-2">
                  <h6 style="font-size: 14px; line-height: 14px">
                    不盲目自信，不盲目崇拜，太聪明的人学不会标杆
                  </h6>
                  <span class="text-muted" style="font-size: 12px">
                    任老板“先僵化，后优化，再固化，坚定不移削足适履穿美国鞋”的策略挽救了华为的学标杆项目，也包括“谁反对，我们就换掉谁”的坚定意志</span
                  >
                </li>
                <li class="mt-2">
                  <h6 style="font-size: 14px; line-height: 14px">
                    缺什么补什么，切忌照抄照搬，找到底层逻辑
                  </h6>
                  <span class="text-muted" style="font-size: 12px">
                    第一条分析线：看背景，是什么，为什么，怎么做，啥效果，啥边界<br />
                    第二条学习线：啥现状，学什么，为啥学，如何用，怎优化，怎推广
                  </span>
                </li>
                <li class="mt-2">
                  <h6 style="font-size: 14px; line-height: 14px">
                    学习标杆分解大法
                  </h6>
                  <span class="text-muted" style="font-size: 12px">
                    学习他们的产品，分解到无法分解为止，找到成功杠杆</span
                  >
                </li>
                <li class="mt-2">
                  <h6 style="font-size: 14px; line-height: 14px">
                    直接套用经过千百次战斗验证过的作战流程、模板、工具
                  </h6>
                  <span class="text-muted" style="font-size: 12px">
                    丰富和完善作战手册五件套，不断形成自己的组织能力</span
                  >
                </li>
              </ol>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--enc container-->
    </section>
    <!--end section-->
    <!-- About End -->

    <!-- Services Start -->

    <section class="section" style="margin-top: -5rem">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">青桐学堂课程目录</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                以下为部分课程目录，如有需求，欢迎直接联系我们；如需求目录里没有，我们可以根据企业实际需求匹配适合的课程和老师
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <div class="row">
          <div
            class="col-lg-2 col-md-2 col-2"
            style="
              background-color: #2eca8b;
              box-shadow: 0px 1px 10px #737e7a;
              border-radius: 5px 0px 0px 5px;
            "
          >
            <div
              style="
                justify-content: center;
                align-items: center;
                height: 100%;
                display: flex;
                text-align: center;
                color: #fff;
              "
            >
              <div>
                <img
                  src="images/icon/lightbulb.svg"
                  class="mb-2"
                  height="80"
                  alt=""
                />
                <div
                  style="font-size: 22px; text-align: center"
                  class="font-weight-bold"
                >
                  卓越领导力<br />
                  <span style="font-size: 14px">学习者：管理者</span>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-10 col-md-10 col-10">
            <div class="row">
              <div
                class="col-lg-2 col-md-2 col-2"
                style="
                  background-color: #39e09d;
                  border-radius: 0px 5px 5px 0px;
                "
              >
                <div
                  style="
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    color: #fff;
                    font-size: 20px;
                  "
                >
                  明方向
                </div>
              </div>
              <div
                class="col-lg-5 col-md-5 col-5"
                style="padding-left: 5px; padding-right: 5px"
              >
                <div
                  style="
                    justify-content: left;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                    display: flex;
                    font-size: 12px;
                  "
                  class="bg-light"
                >
                  《战略到执行的“症”与“因”》<br />
                  《战略制定的理论与方法》<br />
                  《战略制定第一步—如何开展市场洞察》<br />
                  《基于战略意图的业务设计》<br />
                  《从战略规划到年度经营计划》<br />
                  《战略转型与组织变革》
                </div>
              </div>
              <div
                class="col-lg-5 col-md-5 col-5"
                style="padding-left: 5px; padding-right: 5px"
              >
                <div
                  style="
                    justify-content: left;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                    display: flex;
                    font-size: 12px;
                  "
                  class="bg-light"
                >
                  《康志军：认识管理、理解德鲁克的18个关键词》
                  《清北北大都在学：IBM原副总裁决策思维32讲》
                  《陈春花：让管理发挥绩效的8个基本概念》
                  《德鲁克管理经典：卓有成效的管理者》《中国制造的数字化和智能化突围之路》
                  《企业数字化转型》《阿里铁军领导力24讲》<br />
                  《前阿里副总裁卫哲：领导必备技能》<br />
                  《卓有成效的管理者导读》 CEO篇/管理篇/专家篇<br />
                  《上任第一年：新任管理者的十大实战管理问题》
                </div>
              </div>
            </div>
            <div class="row" style="margin-top: 8px">
              <div
                class="col-lg-2 col-md-2 col-2"
                style="
                  background-color: #39e09d;
                  border-radius: 0px 5px 5px 0px;
                "
              >
                <div
                  style="
                    justify-content: center;
                    align-items: center;
                    max-width: 100%;
                    min-width: 100%;
                    height: 100%;
                    display: flex;
                    color: #fff;
                    font-size: 20px;
                  "
                >
                  阔视野
                </div>
              </div>
              <div
                class="col-lg-5 col-md-5 col-5"
                style="padding-left: 5px; padding-right: 5px"
              >
                <div
                  style="
                    justify-content: left;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                    display: flex;
                    font-size: 12px;
                  "
                  class="bg-light"
                >
                  《定制标杆企业游学（阿里/华为等）》<br />
                  《前沿技术—人工智能应用》<br />
                  《迎接5G的机遇与挑战》<br />
                  《5G新技术与新模式应用》<br />
                  《大数据产业先创及应用创新》<br />
                  《金融区块链技术及发展应用》
                </div>
              </div>
              <div
                class="col-lg-5 col-md-5 col-5"
                style="padding-left: 5px; padding-right: 5px"
              >
                <div
                  style="
                    justify-content: left;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                    display: flex;
                    font-size: 12px;
                  "
                  class="bg-light"
                >
                  《赵国栋：元宇宙前沿报告20讲》<br />
                  《于佳宁元宇宙世界观》<br />
                  《人工智能的极限》
                </div>
              </div>
            </div>
            <div class="row" style="margin-top: 8px">
              <div
                class="col-lg-2 col-md-2 col-2"
                style="
                  background-color: #39e09d;
                  border-radius: 0px 5px 5px 0px;
                "
              >
                <div
                  style="
                    justify-content: center;
                    align-items: center;
                    max-width: 100%;
                    min-width: 100%;
                    height: 100%;
                    display: flex;
                    color: #fff;
                    font-size: 20px;
                  "
                >
                  找模式
                </div>
              </div>
              <div
                class="col-lg-5 col-md-5 col-5"
                style="padding-left: 5px; padding-right: 5px"
              >
                <div
                  style="
                    justify-content: left;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                    display: flex;
                    font-size: 12px;
                  "
                  class="bg-light"
                >
                  《互联网时代商业模式创新与转型》<br />
                  《增长与商业模式》<br />
                  《新营销模式》<br />
                  《大数据变革与商业模式创新》<br />
                  《阿里巴巴数字商业模式》
                </div>
              </div>
              <div
                class="col-lg-5 col-md-5 col-5"
                style="padding-left: 5px; padding-right: 5px"
              >
                <div
                  style="
                    justify-content: left;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                    display: flex;
                    font-size: 12px;
                  "
                  class="bg-light"
                >
                  《商业模式九大经营模式解读》<br />
                  《商业模式画布方法论》<br />
                  《 IPD集成产品开发》
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-5 pt-4">
          <div
            class="col-lg-2 col-md-2 col-2"
            style="
              background-color: #f17425;
              box-shadow: 0px 1px 10px #737e7a;
              border-radius: 5px 0px 0px 5px;
            "
          >
            <div
              style="
                justify-content: center;
                align-items: center;
                height: 100%;
                display: flex;
                text-align: center;
                color: #fff;
              "
            >
              <div>
                <img
                  src="images/icon/lightbulb.svg"
                  class="mb-2"
                  height="80"
                  alt=""
                />
                <div
                  style="font-size: 22px; text-align: center"
                  class="font-weight-bold"
                >
                  组织建设力<br />
                  <span style="font-size: 14px">学习者：团队管理层</span>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-10 col-md-10 col-10">
            <div class="row">
              <div
                class="col-lg-2 col-md-2 col-2"
                style="
                  background-color: #f8904f;
                  border-radius: 0px 5px 5px 0px;
                "
              >
                <div
                  style="
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    color: #fff;
                    font-size: 20px;
                  "
                >
                  强组织
                </div>
              </div>
              <div
                class="col-lg-5 col-md-5 col-5"
                style="padding-left: 5px; padding-right: 5px"
              >
                <div
                  style="
                    justify-content: left;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                    display: flex;
                    font-size: 12px;
                  "
                  class="bg-light"
                >
                  《新晋管理者的第一堂管理课》<br />
                  《从业务骨干到优秀管理者》《横向领导力》<br />
                  《管理者角色认知与高绩效团队建设》<br />
                  《组织转型与变革管理》《从游击队到正规军》<br />
                  《数字化敏捷组织转型》《转型领导力》<br />
                  《反僵化：激发组织活力的五大杠杆》
                </div>
              </div>
              <div
                class="col-lg-5 col-md-5 col-5"
                style="padding-left: 5px; padding-right: 5px"
              >
                <div
                  style="
                    justify-content: left;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 5px;
                    display: flex;
                    font-size: 12px;
                  "
                  class="bg-light"
                >
                  《客服管理：打造高效客服团队》<br />
                  《陈春花：数字化时代的组织管理新逻辑》<br />
                  《协同：数字化时代组织效率的本质》<br />
                  《以双赋能（能力+能量）为导向的组织经验萃取》<br />
                  《组织创新99讲（新春版）》
                </div>
              </div>
            </div>
            <div class="row" style="margin-top: 8px">
              <div
                class="col-lg-2 col-md-2 col-2"
                style="
                  background-color: #f8904f;
                  border-radius: 0px 5px 5px 0px;
                "
              >
                <div
                  style="
                    justify-content: center;
                    align-items: center;
                    max-width: 100%;
                    min-width: 100%;
                    height: 100%;
                    display: flex;
                    color: #fff;
                    font-size: 20px;
                  "
                >
                  善用人
                </div>
              </div>
              <div
                class="col-lg-5 col-md-5 col-5"
                style="padding-left: 5px; padding-right: 5px"
              >
                <div
                  style="
                    justify-content: left;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                    display: flex;
                    font-size: 12px;
                  "
                  class="bg-light"
                >
                  《人才经营突破——CEO的人才管理课》 <br />
                  《人才培养与人才梯队建设》<br />
                  《华为管理之道与干部管理》<br />
                  《业务领导者的人才管理课》<br />
                  《变革领导力—拥抱转换，创造未来》<br />
                  《横向领导力—组织中同频共进》 《管理者的三项修炼》
                </div>
              </div>
              <div
                class="col-lg-5 col-md-5 col-5"
                style="padding-left: 5px; padding-right: 5px"
              >
                <div
                  style="
                    justify-content: left;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                    display: flex;
                    font-size: 12px;
                  "
                  class="bg-light"
                >
                  《业务领导者的人才管理课》<br />
                  《新任管理者的十大实战管理问题》 《21天打造招聘专家》
                  <br />《45天HR培训实战训练营》<br />《OKR目标管理法》
                  《冰鉴：曾国藩教你识人、用人》<br />
                  《管理三板斧拆解：批量生产人才》
                </div>
              </div>
            </div>
            <div class="row" style="margin-top: 8px">
              <div
                class="col-lg-2 col-md-2 col-2"
                style="
                  background-color: #f8904f;
                  border-radius: 0px 5px 5px 0px;
                "
              >
                <div
                  style="
                    justify-content: center;
                    align-items: center;
                    max-width: 100%;
                    min-width: 100%;
                    height: 100%;
                    display: flex;
                    color: #fff;
                    font-size: 20px;
                  "
                >
                  筑文化
                </div>
              </div>
              <div
                class="col-lg-5 col-md-5 col-5"
                style="padding-left: 5px; padding-right: 5px"
              >
                <div
                  style="
                    justify-content: left;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                    display: flex;
                    font-size: 12px;
                  "
                  class="bg-light"
                >
                  《企业文化与企业文化建设》<br />
                  《建构可执行的企业文化体系》<br />
                  《华为企业文化与基本法解读》<br />
                  《阿里巴巴价值观与企业文化建设》<br />
                  《创建学习型组织的五项修炼》<br />
                  《打造高效团队执行力文化》
                </div>
              </div>
              <div
                class="col-lg-5 col-md-5 col-5"
                style="padding-left: 5px; padding-right: 5px"
              >
                <div
                  style="
                    justify-content: left;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                    display: flex;
                    font-size: 12px;
                  "
                  class="bg-light"
                >
                  《企业文化落地系统》 <br />《欧德张 . 做实企业文化》<br />
                  《企业文化落地运营的六脉神剑》<br />
                  《12堂课甩掉洗脑套路，让你成为企业文化创新传 播高手》<br />
                  《文化战“疫” 》 <br />《企业文化落地最佳实践》
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-5 pt-4">
          <div
            class="col-lg-2 col-md-2 col-2"
            style="
              background-color: #007bff;
              box-shadow: 0px 1px 10px #737e7a;
              border-radius: 5px 0px 0px 5px;
            "
          >
            <div
              style="
                justify-content: center;
                align-items: center;
                height: 100%;
                display: flex;
                text-align: center;
                color: #fff;
              "
            >
              <div>
                <img
                  src="images/icon/lightbulb.svg"
                  class="mb-2"
                  height="80"
                  alt=""
                />
                <div
                  style="font-size: 22px; text-align: center"
                  class="font-weight-bold"
                >
                  个人关键能力<br />
                  <span style="font-size: 14px">学习者：企业员工</span>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-10 col-md-10 col-10">
            <div class="row">
              <div
                class="col-lg-2 col-md-2 col-2"
                style="
                  background-color: #469efc;
                  border-radius: 0px 5px 5px 0px;
                "
              >
                <div
                  style="
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    color: #fff;
                    font-size: 20px;
                  "
                >
                  增业绩
                </div>
              </div>
              <div
                class="col-lg-5 col-md-5 col-5"
                style="padding-left: 5px; padding-right: 5px"
              >
                <div
                  style="
                    justify-content: left;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                    display: flex;
                    font-size: 12px;
                  "
                  class="bg-light"
                >
                  《企业六维成交理论》《销售目标突破与执行》
                  <br />《从客户需求到客户满意度管理》<br />
                  《客户关系管理与客户需求挖掘》 《销冠之路-影响力销售》
                  <br />《成交力—业绩倍增就像呼吸一样简单》
                  <br />《策略致胜—大客户策略销售》
                  <br />《销售心理学—超级销售拜访技巧》
                </div>
              </div>
              <div
                class="col-lg-5 col-md-5 col-5"
                style="padding-left: 5px; padding-right: 5px"
              >
                <div
                  style="
                    justify-content: left;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 5px;
                    display: flex;
                    font-size: 12px;
                  "
                  class="bg-light"
                >
                  《阿里铁军领导力24讲》 《如何打造高绩效的销售团队》
                  <br />《让大客户营销人员业绩倍增的30堂进阶训练课》
                  <br />《阿里销售冠军：销售精英进阶课》
                  <br />《华为营销实战：如何成为金牌销售》 <br />《实战谈判技巧
                  》 《营销中的心理学》
                  <br />《阿里铁军销售管理秘籍：如何达成业绩翻倍增长》
                </div>
              </div>
            </div>
            <div class="row" style="margin-top: 8px">
              <div
                class="col-lg-2 col-md-2 col-2"
                style="
                  background-color: #469efc;
                  border-radius: 0px 5px 5px 0px;
                "
              >
                <div
                  style="
                    justify-content: center;
                    align-items: center;
                    max-width: 100%;
                    min-width: 100%;
                    height: 100%;
                    display: flex;
                    color: #fff;
                    font-size: 20px;
                  "
                >
                  精财务
                </div>
              </div>
              <div
                class="col-lg-5 col-md-5 col-5"
                style="padding-left: 5px; padding-right: 5px"
              >
                <div
                  style="
                    justify-content: left;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                    display: flex;
                    font-size: 12px;
                  "
                  class="bg-light"
                >
                  《总经理财务思维》
                  <br />《企业经营周期财务指标解码》
                  <br />《成本思维—经营人员财务管理和决策》
                  <br />《企业成本控制方法与手段》 <br />《企业税务筹划》
                  <br />《应收账款管理及营运资本管理》
                  <br />《非财务经理的财务课》
                </div>
              </div>
              <div
                class="col-lg-5 col-md-5 col-5"
                style="padding-left: 5px; padding-right: 5px"
              >
                <div
                  style="
                    justify-content: left;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                    display: flex;
                    font-size: 12px;
                  "
                  class="bg-light"
                >
                  《徐远的投资课，手把手教你财富增值》
                  <br />《财务人员数据分析之道/第二季岗位实战》
                  <br />《向华为学习： 企业财务数字化转型》》
                  <br />《透彻的财务分析能力之报表分析系列》
                  <br />《52家上市公司财务报表每周一讲，看透公司能力》
                  <br />《尽职调查专栏：财务/法务/业务全系列》
                  <br />《16年财务专家教你如何看上市公司财报》
                </div>
              </div>
            </div>
            <div class="row" style="margin-top: 8px">
              <div
                class="col-lg-2 col-md-2 col-2"
                style="
                  background-color: #469efc;
                  border-radius: 0px 5px 5px 0px;
                "
              >
                <div
                  style="
                    justify-content: center;
                    align-items: center;
                    max-width: 100%;
                    min-width: 100%;
                    height: 100%;
                    display: flex;
                    color: #fff;
                    font-size: 20px;
                  "
                >
                  提效率
                </div>
              </div>
              <div
                class="col-lg-5 col-md-5 col-5"
                style="padding-left: 5px; padding-right: 5px"
              >
                <div
                  style="
                    justify-content: left;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                    display: flex;
                    font-size: 12px;
                  "
                  class="bg-light"
                >
                  《企业管理变革与流程管理实践》
                  <br />《对准客户价值的流程管理变革》 <br />
                  《引爆效率—用流程复制》 <br />
                  《华为基于流程的项目管理》 <br />《敏捷目标与任务管理工作坊》
                  <br />《管理技能提升》《高效制作高端PPT》
                </div>
              </div>
              <div
                class="col-lg-5 col-md-5 col-5"
                style="padding-left: 5px; padding-right: 5px"
              >
                <div
                  style="
                    justify-content: left;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                    display: flex;
                    font-size: 12px;
                  "
                  class="bg-light"
                >
                  《笔记侠柯洲手把手教你做笔记》
                  <br />《 word从小白到大能手，提高桌面生产力》 <br />
                  《 72节课：跟李锐学Excel，从入门到精通》
                  <br />《视频号掘金指南 | 跟秋叶从0到1学视频号运营》 <br />
                  《百节社交招聘录屏实操微课》《PPT逻辑美学》
                  <br />《培训师站稳讲台必备课程（TTT）》
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>

    <section class="section bg-light">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">成人学习的六种模式</h4>

              <h5 class="text-success para-desc mx-auto mb-0">
                加+减+乘+除+乘方+开方
              </h5>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
            <div class="features text-center pt-3 pb-3">
              <img src="images/icon/24gl-plusCircle.svg" height="50" alt="" />
              <div class="content mt-3">
                <h4 class="title-2 text-success">加法，获取新知识</h4>
                <p class="text-muted">
                  在原有知识基础上的拓展与叠加，知道以前不知道的
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
            <div class="features text-center pt-3 pb-3">
              <img src="images/icon/24gl-minusCircle.svg" height="50" alt="" />
              <div class="content mt-3">
                <h4 class="title-2 text-success">减法，去除偏见</h4>
                <p class="text-muted">
                  去除以前持有的偏见、傲慢、误解，拨开云雾，聚焦在更重要的领域
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
            <div class="features text-center pt-3 pb-3">
              <img src="images/icon/chengfa.svg" height="50" alt="" />
              <div class="content mt-3">
                <h4 class="title-2 text-success">乘法，触发新思考</h4>
                <p class="text-muted">
                  用新获得的知识和视角，与经验发生化学反应，形成新的认知
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
            <div class="features text-center pt-3 pb-3">
              <img src="images/icon/chu.svg" height="50" alt="" />
              <div class="content mt-3">
                <h4 class="title-2 text-success">除法，形成新体系</h4>
                <p class="text-muted">
                  梳理既有的知识和经验，形成自己的认知逻辑和框架，使其更系统性
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
            <div class="features text-center pt-3 pb-3">
              <img src="images/icon/pf.svg" height="50" alt="" />
              <div class="content mt-3">
                <h4 class="title-2 text-success">乘方，持续新升级</h4>
                <p class="text-muted">
                  通过新体验和新体悟，升级现有知识和体验，在同级中具备更高的视野
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
            <div class="features text-center pt-3 pb-3">
              <img src="images/icon/kaifan.svg" height="50" alt="" />
              <div class="content mt-3">
                <h4 class="title-2 text-success">开方，提炼最本质</h4>
                <p class="text-muted">
                  不断积累认知，化繁为简，从自我知识库经验中提炼最为本质的部分
                </p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Services End -->

    <div class="container mt-100 mt-60" id="instructors">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">青桐大咖讲师团队</h4>
            <p class="text-muted para-desc mx-auto mb-0">
              管理咨询行业资深大咖，理论结合训战，深谙标杆企业快速成长之道
            </p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->

      <div class="row">
        <div class="col-lg-3 col-md-6 mt-4 pt-2">
          <div class="card team text-center rounded border-0">
            <div class="card-body">
              <div class="position-relative">
                <img
                  src="images/client/14.png"
                  class="img-fluid avatar avatar-large rounded-circle shadow"
                  alt=""
                />
                <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                  <li class="list-inline-item">
                    <div
                      v-b-modal.modal1
                      data-toggle="modal"
                      data-target="#modal1"
                      class="btn btn-pills btn-soft-warning btn-sm mouse-down"
                    >
                      查看详情
                    </div>
                  </li>
                </ul>
                <b-modal id="modal1" title="邢老师" hide-footer centered>
                  <div class="bg-white p-3 rounded box-shadow">
                    <p class="text-muted mb-0">
                      <span class="text-warning font-weight-bold">背景：</span
                      >原华为公司副总裁、公司运作计划部总裁、流程与IT管理部原副总裁、变革项目管理办主任、IBM-HUAWEI
                      集成供应链项目群总负责人
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      华为“蓝血十杰”之一。北京大学数学系毕业，资深产业研究专家、企业战略和变革管理专家，多家上市公司管理咨询顾问
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">课程：</span
                      >《企业大生命周期的管理战略》《以客户为中心的管理体系建设》《以客户价值为核心的流程管理变革》《华为企业文化与干部管理》《华为国际化实践》
                    </p>
                  </div>
                </b-modal>
                <!--end icon-->
              </div>
              <div class="content pt-3">
                <h5 class="mb-0">
                  <a href="javascript:void(0)" class="name text-dark">邢老师</a>
                </h5>
                <small class="designation text-muted"
                  >资深投资专家、企业战略管理专家</small
                >
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 mt-4 pt-2">
          <div class="card team text-center rounded border-0">
            <div class="card-body">
              <div class="position-relative">
                <img
                  src="images/client/15.png"
                  class="img-fluid avatar avatar-large rounded-circle shadow"
                  alt=""
                />
                <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                  <li class="list-inline-item">
                    <div
                      v-b-modal.modal2
                      data-toggle="modal"
                      data-target="#modal2"
                      class="btn btn-pills btn-soft-warning btn-sm mouse-down"
                    >
                      查看详情
                    </div>
                  </li>
                </ul>
                <b-modal id="modal2" title="施老师" hide-footer centered>
                  <div class="bg-white p-3 rounded box-shadow">
                    <p class="text-muted mb-0">
                      <span class="text-warning font-weight-bold">背景：</span>
                      华中科技大学管理科学与工程专业（博士），中国人民大学金融与证券研究所高级研究员，北京华夏基石管理咨询集团合伙人，企业战略和市场营销专家
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">领域：</span>
                      营销策略 企业战略 资本运营等领域
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">成果：</span>
                      企业管理体系构建的POC模型、中国企业成长的变量体系、中国企业战略转型、多层次中国市场营销战略、顾客价值创新方法
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">著作：</span>
                      《企业资本运营》《企业战略思维》《营销策划》《经济体制改革辞典》《西方劳工制度》
                    </p>
                  </div>
                </b-modal>
                <!--end icon-->
              </div>
              <div class="content pt-3">
                <h5 class="mb-0">
                  <a href="javascript:void(0)" class="name text-dark">施老师</a>
                </h5>
                <small class="designation text-muted"
                  >管理学博士、知名战略管理专家</small
                >
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 mt-4 pt-2">
          <div class="card team text-center rounded border-0">
            <div class="card-body">
              <div class="position-relative">
                <img
                  src="images/client/16.png"
                  class="img-fluid avatar avatar-large rounded-circle shadow"
                  alt=""
                />
                <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                  <li class="list-inline-item">
                    <div
                      v-b-modal.modal3
                      data-toggle="modal"
                      data-target="#modal3"
                      class="btn btn-pills btn-soft-warning btn-sm mouse-down"
                    >
                      查看详情
                    </div>
                  </li>
                </ul>
                <b-modal id="modal3" title="王老师" hide-footer centered>
                  <div class="bg-white p-3 rounded box-shadow">
                    <p class="text-muted mb-0">
                      <span class="text-warning font-weight-bold">背景：</span>
                      曾任华为全球服务部副总裁兼干部部部长、华为亚太地区部副总裁、资深人力资源专家、资深项目管理专家。18年华为工作经历，在国内、总部、海外片区、代表处等多岗位经验；见证华为从早期到不断发展壮大的历程，参与最难的项目管理，制定落实诸多重大人力资源政策，是同时具有战略高度和落地深度的专家型管理者
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">领域：</span>
                      战略制定与落地、组织治理、干部领导力、企业文化、人力资源管理
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">亮点：</span>
                      具有企业战略高度、战略与组织落地深度的顾问；用心洞察痛点，理解需求，提出命中要害、直击痛点的解决方案
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">著作：</span>
                      《企业文化落地》、《华为绩效管理》、《华为干部管理》
                    </p>
                  </div>
                </b-modal>
                <!--end icon-->
              </div>
              <div class="content pt-3">
                <h5 class="mb-0">
                  <a href="javascript:void(0)" class="name text-dark">王老师</a>
                </h5>
                <small class="designation text-muted"
                  >领衔专家、资深管理咨询顾问</small
                >
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 mt-4 pt-2">
          <div class="card team text-center rounded border-0">
            <div class="card-body">
              <div class="position-relative">
                <img
                  src="images/client/17.png"
                  class="img-fluid avatar avatar-large rounded-circle shadow"
                  alt=""
                />
                <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                  <li class="list-inline-item">
                    <div
                      v-b-modal.modal4
                      data-toggle="modal"
                      data-target="#modal4"
                      class="btn btn-pills btn-soft-warning btn-sm mouse-down"
                    >
                      查看详情
                    </div>
                  </li>
                </ul>
                <b-modal id="modal4" title="王老师" hide-footer centered>
                  <div class="bg-white p-3 rounded box-shadow">
                    <p class="text-muted mb-0">
                      <span class="text-warning font-weight-bold">背景：</span>
                      曾任华为国家副总经理、总经理，五级营销专家（最高级别）；19年华为工作经历，其中15年常驻海外，包括泰国、南非、委内瑞拉等国家；从事产品开发、销售、营销管理，到子公司全面经营管理，参与华为LTC（从线索到回款）流程试点与变革落地
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">领域：</span>
                      战略制定与落地、营销体系建设及能力提升、企业管理与经营
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">亮点：</span>
                      同时具有全面经营管理系统性、营销专业深度的顾问；多年一线工作经历，对MTL（从市场到线索）、LTC（从线索到回款）和MCR（管理客户关系）流程落地有丰富经验，帮助客户立体式打造营销动力引擎
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">著作：</span>
                      《华为战略解码》
                    </p>
                  </div>
                </b-modal>
                <!--end icon-->
              </div>
              <div class="content pt-3">
                <h5 class="mb-0">
                  <a href="javascript:void(0)" class="name text-dark">王老师</a>
                </h5>
                <small class="designation text-muted"
                  >领衔专家、资深管理咨询顾问</small
                >
              </div>
            </div>
          </div>
        </div>
        <div class="container mt-5">
          <div class="row">
            <div class="col-12 text-center">
              <div class="section-title mb-2 pb-2">
                <h4 class="title">青桐核心讲师团队</h4>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->

          <div class="row" style="padding-left: 50px">
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
              <div class="media align-items-center">
                <div class="card team text-center rounded border-0">
                  <img
                    src="images/client/18.png"
                    class="avatar avatar-medium rounded-circle img-thumbnail"
                    alt=""
                  />
                  <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                    <li class="list-inline-item">
                      <div
                        v-b-modal.modal5
                        data-toggle="modal"
                        data-target="#modal5"
                        class="btn btn-pills btn-soft-warning btn-sm mouse-down"
                      >
                        查看详情
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="content ml-3">
                  <h5 class="mb-0">
                    <a
                      href="javascript:void(0)"
                      class="name text-dark"
                      v-b-modal.modal5
                      data-toggle="modal"
                      data-target="#modal5"
                      >康老师</a
                    >
                  </h5>
                  <small class="position text-muted"
                    >战略合作老师、知名管理咨询专家</small
                  >
                </div>
                <b-modal id="modal5" title="康老师" hide-footer centered>
                  <div class="bg-white p-3 rounded box-shadow">
                    <p class="text-muted mb-0">
                      <span class="text-warning font-weight-bold">背景：</span>
                      毕业于南京大学商学院，16年管理实践与咨询经验。曾在多家大型企业担任首席人力资源官、变革委员会成员等职位，在团队管理、组织变革、领导梯队建设、空降高管融入管理等领域有丰富的经验
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">著作：</span>
                      著有《人力资源开发阅读地图》（2010年）《HR转型突破》（2013年）《事业合伙人》（2016年）；译有《关键人才决策》《无边界组织》《重新定义人才》，审校有《在组织中高效学习》《高绩效的HR》等尤里奇HR转型系列、《上任第一年》系列等
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">课程：</span>
                      CEO的人才管理课》《人才经营突破》
                    </p>
                  </div>
                </b-modal>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
              <div class="media align-items-center">
                <div class="card team text-center rounded border-0">
                  <img
                    src="images/client/19.png"
                    class="avatar avatar-medium rounded-circle img-thumbnail"
                    alt=""
                  />
                  <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                    <li class="list-inline-item">
                      <div
                        v-b-modal.modal6
                        data-toggle="modal"
                        data-target="#modal6"
                        class="btn btn-pills btn-soft-warning btn-sm mouse-down"
                      >
                        查看详情
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="content ml-3">
                  <h5 class="mb-0">
                    <a
                      href="javascript:void(0)"
                      class="name text-dark"
                      v-b-modal.modal6
                      data-toggle="modal"
                      data-target="#modal6"
                      >吴老师</a
                    >
                  </h5>
                  <small class="position text-muted"
                    >战略合作老师、知名管理咨询专家</small
                  >
                </div>
                <b-modal id="modal6" title="吴老师" hide-footer centered>
                  <div class="bg-white p-3 rounded box-shadow">
                    <p class="text-muted mb-0">
                      <span class="text-warning font-weight-bold">背景：</span>
                      毕业于东南大学，MBA学历，曾任某高校南昆士兰大学《Electronic
                      commerce》课程讲师。七年企业高管经验，曾担任某上市公司原监事会主席、某大型集团原企业商学院院长、某大型集团管理学院副院长，28岁担任企业（2000人规模）常务副总经理具有丰富的实战管理经验
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">著作：</span>
                      译有《卓越领导之旅》《奥兹的智慧》《在组织中高效学习》《家族企业治理：家族与企业的平衡和繁荣》等
                    </p>
                  </div>
                </b-modal>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
              <div class="media align-items-center">
                <div class="card team text-center rounded border-0">
                  <img
                    src="images/client/20.png"
                    class="avatar avatar-medium rounded-circle img-thumbnail"
                    alt=""
                  />
                  <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                    <li class="list-inline-item">
                      <div
                        v-b-modal.modal7
                        data-toggle="modal"
                        data-target="#modal7"
                        class="btn btn-pills btn-soft-warning btn-sm mouse-down"
                      >
                        查看详情
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="content ml-3">
                  <h5 class="mb-0">
                    <a
                      href="javascript:void(0)"
                      class="name text-dark"
                      v-b-modal.modal7
                      data-toggle="modal"
                      data-target="#modal7"
                      >张老师</a
                    >
                  </h5>
                  <small class="position text-muted"
                    >前阿里巴巴人力专家、资深培训师</small
                  >
                </div>
                <b-modal id="modal7" title="张老师" hide-footer centered>
                  <div class="bg-white p-3 rounded box-shadow">
                    <p class="text-muted mb-0">
                      <span class="text-warning font-weight-bold">背景：</span>
                      1999年加入阿里巴巴，工号38号，先后担任过国际网站运营专员、招聘主管、设计部经理、培训部副总监、人力资源总监和阿里学院负责人等职务，现担任多家公司管理培训师、高管教练和咨询顾问
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">领域：</span>
                      在企业文化建设、组织发展、人才培养、团队管理、个人成长等方面有丰富的实战阅历和辅导经验。授课内容简单实用、接地气，授课方式注重实战演练和互动研讨，授课风格生动有趣
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">课程：</span>
                      《政委领导力》
                      《管理三板斧》《六顶思考帽》《创业团队企业文化建设》
                    </p>
                  </div>
                </b-modal>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
              <div class="media align-items-center">
                <div class="card team text-center rounded border-0">
                  <img
                    src="images/client/21.png"
                    class="avatar avatar-medium rounded-circle img-thumbnail"
                    alt=""
                  />
                  <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                    <li class="list-inline-item">
                      <div
                        v-b-modal.modal8
                        data-toggle="modal"
                        data-target="#modal8"
                        class="btn btn-pills btn-soft-warning btn-sm mouse-down"
                      >
                        查看详情
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="content ml-3">
                  <h5 class="mb-0">
                    <a
                      href="javascript:void(0)"
                      class="name text-dark"
                      v-b-modal.modal8
                      data-toggle="modal"
                      data-target="#modal8"
                      >吴老师</a
                    >
                  </h5>
                  <small class="position text-muted"
                    >战略合作老师、学习体系构建专家</small
                  >
                </div>
                <b-modal id="modal8" title="吴老师" hide-footer centered>
                  <div class="bg-white p-3 rounded box-shadow">
                    <p class="text-muted mb-0">
                      <span class="text-warning font-weight-bold">背景：</span>
                      阿里研究院高级研究员，《阿里商业评论》执行主编，主要从事互联网战略、产业、政策研究。国家新闻出版广电总局“全国报刊媒体融合创新案例征集项目”评审专家，清华大学继续教育学院客座讲师，华闻传媒研究院创研导师，同时在国家行政学院、北京市委党校等任兼职讲师。曾任百度党委副书记，百度发展研究中心研究员，北京市政府新闻办公室媒体服务处副处长，北京奥运新闻中心媒体接待组组长，在政府机关工作10年
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">著作：</span>
                      《互联网+：从IT到DT》《阿里商业评论
                      跨境电商》《阿里商业评论
                      平台经济》《互联网+：未来空间无限》
                    </p>
                  </div>
                </b-modal>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
              <div class="media align-items-center">
                <div class="card team text-center rounded border-0">
                  <img
                    src="images/client/22.png"
                    class="avatar avatar-medium rounded-circle img-thumbnail"
                    alt=""
                  />
                  <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                    <li class="list-inline-item">
                      <div
                        v-b-modal.modal9
                        data-toggle="modal"
                        data-target="#modal9"
                        class="btn btn-pills btn-soft-warning btn-sm mouse-down"
                      >
                        查看详情
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="content ml-3">
                  <h5 class="mb-0">
                    <a
                      href="javascript:void(0)"
                      class="name text-dark"
                      v-b-modal.modal9
                      data-toggle="modal"
                      data-target="#modal9"
                      >邓老师</a
                    >
                  </h5>
                  <small class="position text-muted"
                    >特聘老师、 人力资源实战专家
                  </small>
                </div>
                <b-modal id="modal9" title="邓老师" hide-footer centered>
                  <div class="bg-white p-3 rounded box-shadow">
                    <p class="text-muted mb-0">
                      <span class="text-warning font-weight-bold">背景：</span>
                      英国格林威治大学项目管理硕士。从事人力资源工作21年，曾任奥林巴斯中国制造总部人力资源经理，华强供应链集团人力资源总监，深圳冠欣矿业投资有限公司人力资源总经理，华润三九人力资源总监
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">领域：</span>
                      拥有“国际培训师”资质十几年，华润集团讲师团成员，合益MGL领导力课程认证讲师，华润“卓越经理人”训练营讲师
                      曾为华润集团、招商地产、平安集团、中粮集团、等多家知名企业、以及各类型各行业的中小企业的各类团队授课。在生物医疗、创意设计、精密制造等行业有丰富的管理咨询项目经验
                    </p>
                  </div>
                </b-modal>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
              <div class="media align-items-center">
                <div class="card team text-center rounded border-0">
                  <img
                    src="images/client/23.png"
                    class="avatar avatar-medium rounded-circle img-thumbnail"
                    alt=""
                  />
                  <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                    <li class="list-inline-item">
                      <div
                        v-b-modal.modal10
                        data-toggle="modal"
                        data-target="#modal10"
                        class="btn btn-pills btn-soft-warning btn-sm mouse-down"
                      >
                        查看详情
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="content ml-3">
                  <h5 class="mb-0">
                    <a
                      href="javascript:void(0)"
                      class="name text-dark"
                      v-b-modal.modal10
                      data-toggle="modal"
                      data-target="#modal10"
                      >徐老师</a
                    >
                  </h5>
                  <small class="position text-muted"
                    >特聘老师 、资深培训专家
                  </small>
                </div>
                <b-modal id="modal10" title="徐老师" hide-footer centered>
                  <div class="bg-white p-3 rounded box-shadow">
                    <p class="text-muted mb-0">
                      <span class="text-warning font-weight-bold">背景：</span>
                      DISC顾问技术认证讲师，DISC沟通技术认证讲师，Meta-Coaching认证教练；中山大学岭南学院EDP特聘讲师
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">荣誉：</span>
                      《中国培训》2017年“我是好讲师”全国大赛“梦想导师”
                      《中国培训》2016年第二届“我有好课程”大赛“全国十强”，《中国培训》杂志2014第二届“我是好讲师”大赛——全国三十强，“最佳创新奖”，“职业素养与管理技能”赛场最高分选手
                      广东省人力资源研究会
                      “2014年度广东省卓越人力资源奖十大杰出培训师”、广东省企业培训研究会创始理事；原58集团中华英才网南区域培训总监
                    </p>
                  </div>
                </b-modal>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
              <div class="media align-items-center">
                <div class="card team text-center rounded border-0">
                  <img
                    src="images/client/24.png"
                    class="avatar avatar-medium rounded-circle img-thumbnail"
                    alt=""
                  />
                  <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                    <li class="list-inline-item">
                      <div
                        v-b-modal.modal11
                        data-toggle="modal"
                        data-target="#modal11"
                        class="btn btn-pills btn-soft-warning btn-sm mouse-down"
                      >
                        查看详情
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="content ml-3">
                  <h5 class="mb-0">
                    <a
                      href="javascript:void(0)"
                      class="name text-dark"
                      v-b-modal.modal11
                      data-toggle="modal"
                      data-target="#modal11"
                      >Robin老师</a
                    >
                  </h5>
                  <small class="position text-muted"
                    >特聘老师、 战略与营销高级顾问
                  </small>
                </div>
                <b-modal id="modal11" title="Robin老师" hide-footer centered>
                  <div class="bg-white p-3 rounded box-shadow">
                    <p class="text-muted mb-0">
                      <span class="text-warning font-weight-bold">背景：</span>
                      曾任16年华为中高层管理经验以及8年北美公司管理经验；曾任华为亚太地区部副总裁等，曾任加拿大iTalkBB公司副总裁、泛亚环保加拿大公司总经理、北京宇畔公司CEO等职位。20+年国内标杆企业及海外工作经验。5+年的企业高级顾问和业务变革经验
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">领域：</span>
                      企业战略，市场营销，国际业务开拓，业务体系建设与运作管理，企业文化建设
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">课程：</span>
                      《企业国际化成功路径与方法》《企业文化与领导力建设》《一线呼唤炮火的营销体系运作》《营销项目管理与关键控制点》《营销与解决方案创新》《专业化营销转型升级》
                    </p>
                  </div>
                </b-modal>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
              <div class="media align-items-center">
                <div class="card team text-center rounded border-0">
                  <img
                    src="images/client/25.png"
                    class="avatar avatar-medium rounded-circle img-thumbnail"
                    alt=""
                  />
                  <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                    <li class="list-inline-item">
                      <div
                        v-b-modal.modal12
                        data-toggle="modal"
                        data-target="#modal12"
                        class="btn btn-pills btn-soft-warning btn-sm mouse-down"
                      >
                        查看详情
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="content ml-3">
                  <h5 class="mb-0">
                    <a
                      href="javascript:void(0)"
                      class="name text-dark"
                      v-b-modal.modal12
                      data-toggle="modal"
                      data-target="#modal12"
                      >邓老师</a
                    >
                  </h5>
                  <small class="position text-muted"
                    >特聘老师、 资深培训专家
                  </small>
                </div>
                <b-modal id="modal12" title="邓老师" hide-footer centered>
                  <div class="bg-white p-3 rounded box-shadow">
                    <p class="text-muted mb-0">
                      <span class="text-warning font-weight-bold">背景：</span>
                      管理顾问，财经作家，书享界创始人。九三学社社员，中国金融作家协会会员。曾在华为公司任职11年，华为原中国区规划咨询总监，华友会华为管理研究院执行院长
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">课程：</span>
                      主讲《华为管理之道》
                      《华为成长之路》系列课程500余场，深受企业家好评
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">著作：</span>
                      代表作有“学习华为三部曲”（《华为管理之道》《华为成长之路》《华为学习之法》），合著《华为数字化转型》《数字化路径》《秒懂力》《打胜仗》等。多部著作被翻译为日语、繁体中文等语言出版发行
                    </p>
                  </div>
                </b-modal>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
              <div class="media align-items-center">
                <div class="card team text-center rounded border-0">
                  <img
                    src="images/client/26.png"
                    class="avatar avatar-medium rounded-circle img-thumbnail"
                    alt=""
                  />
                  <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                    <li class="list-inline-item">
                      <div
                        v-b-modal.modal13
                        data-toggle="modal"
                        data-target="#modal13"
                        class="btn btn-pills btn-soft-warning btn-sm mouse-down"
                      >
                        查看详情
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="content ml-3">
                  <h5 class="mb-0">
                    <a
                      href="javascript:void(0)"
                      class="name text-dark"
                      v-b-modal.modal13
                      data-toggle="modal"
                      data-target="#modal13"
                      >胡老师</a
                    >
                  </h5>
                  <small class="position text-muted"
                    >高级合伙人、 高级管理咨询顾问
                  </small>
                </div>
                <b-modal id="modal13" title="胡老师" hide-footer centered>
                  <div class="bg-white p-3 rounded box-shadow">
                    <p class="text-muted mb-0">
                      <span class="text-warning font-weight-bold">背景：</span>
                      曾任华为软件业务部HRBP与招聘接口人，华润三九医药HR模块负责人、营销HRBP；15年工作经历，在医药、通信、金融等行业有丰富的组织与人力资源管理经验；曾作为核心成员参与麦肯锡、美世、翰威特、德勤等咨询公司合作的战略与组织发展、绩效与薪酬、胜任力与任职资格、HR能力建设等咨询项目
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">领域：</span>
                      组织管理（战略解码、组织管控、组织绩效、文化氛围等）、人才管理（人才盘点与规划、能力模型构建、绩效管理、薪酬激励等
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">亮点：</span>
                      负责多家上市公司或拟上市公司的组织与人才管理咨询项目，有完整的落地实战经验；具有经营管理高度、人力资源系统性和专业深度的顾问；找准经营增长和管理适配的突破口，提出综合解决方案
                    </p>
                  </div>
                </b-modal>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
              <div class="media align-items-center">
                <div class="card team text-center rounded border-0">
                  <img
                    src="images/client/27.png"
                    class="avatar avatar-medium rounded-circle img-thumbnail"
                    alt=""
                  />
                  <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                    <li class="list-inline-item">
                      <div
                        v-b-modal.modal14
                        data-toggle="modal"
                        data-target="#modal14"
                        class="btn btn-pills btn-soft-warning btn-sm mouse-down"
                      >
                        查看详情
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="content ml-3">
                  <h5 class="mb-0">
                    <a
                      href="javascript:void(0)"
                      class="name text-dark"
                      v-b-modal.modal14
                      data-toggle="modal"
                      data-target="#modal14"
                      >李老师</a
                    >
                  </h5>
                  <small class="position text-muted"
                    >高级合伙人、 高级管理咨询顾问</small
                  >
                </div>
                <b-modal id="modal14" title="李老师" hide-footer centered>
                  <div class="bg-white p-3 rounded box-shadow">
                    <p class="text-muted mb-0">
                      <span class="text-warning font-weight-bold">背景：</span>
                      曾任世界500强流程管理总监、某大型金融科技集团战略管理部总经理；15年企业管理和咨询经验，拥有丰富的甲乙方战略转型、组织变革和流程管理项目经验，目前为多家上市公司和大型企业提供战略、组织和流程管理咨询服务，是理论与实践并重的实战型顾问
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">领域：</span>
                      战略规划、战略解码、年度经营计划、组织管控、流程优化
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">亮点：</span>
                      华为战略到执行BLM模型在成长型企业落地的丰富实战，曾负责某大型金融科技公司十多个事业部和三十多家省公司的BLM导入、适配和内化，企业三年增长280%，负责多家上市公司或拟上市公司的战略到执行咨询项目。具有企业战略高度、战略规划系统性和专业深度的顾问；提出中小企业成长的双S模型和战略规划“五看五定六设计”模型，帮助中小企业打造增长机会和路径
                    </p>
                  </div>
                </b-modal>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
              <div class="media align-items-center">
                <div class="card team text-center rounded border-0">
                  <img
                    src="images/client/28.png"
                    class="avatar avatar-medium rounded-circle img-thumbnail"
                    alt=""
                  />
                  <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                    <li class="list-inline-item">
                      <div
                        v-b-modal.modal15
                        data-toggle="modal"
                        data-target="#modal15"
                        class="btn btn-pills btn-soft-warning btn-sm mouse-down"
                      >
                        查看详情
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="content ml-3">
                  <h5 class="mb-0">
                    <a
                      href="javascript:void(0)"
                      class="name text-dark"
                      v-b-modal.modal15
                      data-toggle="modal"
                      data-target="#modal14"
                      >周老师</a
                    >
                  </h5>
                  <small class="position text-muted"
                    >特聘老师、 人力资源管理专家
                  </small>
                </div>
                <b-modal id="modal15" title="周老师" hide-footer centered>
                  <div class="bg-white p-3 rounded box-shadow">
                    <p class="text-muted mb-0">
                      <span class="text-warning font-weight-bold">背景：</span>
                      中科院应用心理学硕士，
                      15年战略运营、组织变革、人力资源管理经验，某世界500强企业大学认证讲师，某知名芯片企业董事长特助
                      经历企业创业到快速发展、成熟期以及变革期，从无到数万人规模，丰富的组织人才体系搭建和实战经验，评价考察人数逾万人
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">领域：</span>
                      企业顶层设计和战略管理、经营管理和组织绩效、领导力、人才体系搭建和实战经验
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">课程：</span>
                      《新精英职业生涯规划认证讲师》《DDI“目标选才”（慧眼识英才）》《竞越“驻足思考”（瞬间思考即刻表达）》
                    </p>
                  </div>
                </b-modal>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
              <div class="media align-items-center">
                <div class="card team text-center rounded border-0">
                  <img
                    src="images/client/29.png"
                    class="avatar avatar-medium rounded-circle img-thumbnail"
                    alt=""
                  />
                  <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                    <li class="list-inline-item">
                      <div
                        v-b-modal.modal16
                        data-toggle="modal"
                        data-target="#modal16"
                        class="btn btn-pills btn-soft-warning btn-sm mouse-down"
                      >
                        查看详情
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="content ml-3">
                  <h5 class="mb-0">
                    <a
                      href="javascript:void(0)"
                      class="name text-dark"
                      v-b-modal.modal16
                      data-toggle="modal"
                      data-target="#modal16"
                      >刘老师</a
                    >
                  </h5>
                  <small class="position text-muted"
                    >特聘老师、 领导力/人才培养专家
                  </small>
                </div>
                <b-modal id="modal16" title="刘老师" hide-footer centered>
                  <div class="bg-white p-3 rounded box-shadow">
                    <p class="text-muted mb-0">
                      <span class="text-warning font-weight-bold">背景：</span>
                      《培训》杂志CPTD认证项目导师，培伴APP“培训经理必修课”授课讲师，腾讯咨询特聘讲师；华东师范大学心理学硕士
                      14年人力资源领域经验+2年marketing经验，曾任国美电器、58同城人才发展与学习发展总监
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">领域：</span>
                      领导力发展、情商发展、企业人才培养体系搭建
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">课程：</span>
                      《数字化时代的管理者系列课程》《非人力资源经理的人力资源管理》《正向激励》
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">著作：</span>
                      《积极改变：成就职场达人的情商课》，上市首月登上亚马逊
                      kindle 榜单职场心理类 top6
                    </p>
                  </div>
                </b-modal>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
              <div class="media align-items-center">
                <div class="card team text-center rounded border-0">
                  <img
                    src="images/client/30.png"
                    class="avatar avatar-medium rounded-circle img-thumbnail"
                    alt=""
                  />
                  <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                    <li class="list-inline-item">
                      <div
                        v-b-modal.modal17
                        data-toggle="modal"
                        data-target="#modal17"
                        class="btn btn-pills btn-soft-warning btn-sm mouse-down"
                      >
                        查看详情
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="content ml-3">
                  <h5 class="mb-0">
                    <a
                      href="javascript:void(0)"
                      class="name text-dark"
                      v-b-modal.modal17
                      data-toggle="modal"
                      data-target="#modal17"
                      >邹老师</a
                    >
                  </h5>
                  <small class="position text-muted"
                    >特聘老师、 职业素养提升专家
                  </small>
                </div>
                <b-modal id="modal17" title="邹老师" hide-footer centered>
                  <div class="bg-white p-3 rounded box-shadow">
                    <p class="text-muted mb-0">
                      <span class="text-warning font-weight-bold">背景：</span>
                      16年世界500强和外资企业营销管理和培训工作，现任深圳潜丰投资控股集团担任培训发展总监。国家高级企业培训师/理财规划师
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">课程：</span>
                      《站稳讲台——新手培训师专业训练课程》《内训师授课技巧提升》《讲好故事赢机会-打动人心的演讲力》《客户沟通与服务人员的五项修炼》《突破式沟通》《服务认知与服务沟通技巧》《中层管理者的沟通艺术》《客户沟通与投诉处理357密码》《阳光心态与情绪压力管理》《职业素养与心态》《非权威领导力-新生代员工管理》
                    </p>
                  </div>
                </b-modal>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
              <div class="media align-items-center">
                <div class="card team text-center rounded border-0">
                  <img
                    src="images/client/31.png"
                    class="avatar avatar-medium rounded-circle img-thumbnail"
                    alt=""
                  />
                  <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                    <li class="list-inline-item">
                      <div
                        v-b-modal.modal18
                        data-toggle="modal"
                        data-target="#modal18"
                        class="btn btn-pills btn-soft-warning btn-sm mouse-down"
                      >
                        查看详情
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="content ml-3">
                  <h5 class="mb-0">
                    <a
                      href="javascript:void(0)"
                      class="name text-dark"
                      v-b-modal.modal18
                      data-toggle="modal"
                      data-target="#modal18"
                      >贺老师</a
                    >
                  </h5>
                  <small class="position text-muted"
                    >特聘老师、 互联网产品专家
                  </small>
                </div>
                <b-modal id="modal18" title="贺老师" hide-footer centered>
                  <div class="bg-white p-3 rounded box-shadow">
                    <p class="text-muted mb-0">
                      <span class="text-warning font-weight-bold">背景：</span>
                      腾讯首批Scrum
                      Master认证，腾讯学院体验式培训讲师/首位满分讲师，13年互联网产品项目经验，华中科技大学工业工程硕士<br />
                      前腾讯QQ空间/QQ浏览器 高级产品经理；<br />
                      QQ音乐/QQ游戏 高级项目经理；<br />
                      前华为高级研发工程师；<br />
                      前iPIN科技产品总监
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">课程：</span>
                      《敏捷项目管理》、《产品经理之道》
                    </p>
                  </div>
                </b-modal>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
              <div class="media align-items-center">
                <div class="card team text-center rounded border-0">
                  <img
                    src="images/client/32.png"
                    class="avatar avatar-medium rounded-circle img-thumbnail"
                    alt=""
                  />
                  <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                    <li class="list-inline-item">
                      <div
                        v-b-modal.modal19
                        data-toggle="modal"
                        data-target="#modal19"
                        class="btn btn-pills btn-soft-warning btn-sm mouse-down"
                      >
                        查看详情
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="content ml-3">
                  <h5 class="mb-0">
                    <a
                      href="javascript:void(0)"
                      class="name text-dark"
                      v-b-modal.modal19
                      data-toggle="modal"
                      data-target="#modal19"
                      >杨老师</a
                    >
                  </h5>
                  <small class="position text-muted"
                    >特聘老师、 著名企业转型专家</small
                  >
                </div>
                <b-modal id="modal19" title="杨老师" hide-footer centered>
                  <div class="bg-white p-3 rounded box-shadow">
                    <p class="text-muted mb-0">
                      <span class="text-warning font-weight-bold">背景：</span>
                      12年专注企业转型，参与并见证73家世界500强与165家中国优秀企业转型毕业山东大学、香港城市大学,工信部主管《中国工业与信息化》专栏作家<br />
                      曾在《哈佛商业评论》《清华管理评论》等国际顶级期刊发表多篇转型专业文章
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">著作：</span>
                      《上任第⼀年：从业务骨干向优秀管理者转型》《企业转型那点事》《好员工为什么离开你》《有话好好说》
                    </p>
                  </div>
                </b-modal>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
              <div class="media align-items-center">
                <div class="card team text-center rounded border-0">
                  <img
                    src="images/client/33.png"
                    class="avatar avatar-medium rounded-circle img-thumbnail"
                    alt=""
                  />
                  <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                    <li class="list-inline-item">
                      <div
                        v-b-modal.modal20
                        data-toggle="modal"
                        data-target="#modal20"
                        class="btn btn-pills btn-soft-warning btn-sm mouse-down"
                      >
                        查看详情
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="content ml-3">
                  <h5 class="mb-0">
                    <a
                      href="javascript:void(0)"
                      class="name text-dark"
                      v-b-modal.modal20
                      data-toggle="modal"
                      data-target="#modal20"
                      >李老师</a
                    >
                  </h5>
                  <small class="position text-muted"
                    >特聘老师、 情绪管理导师
                  </small>
                </div>
                <b-modal
                  id="modal20"
                  title="李老师"
                  hide-footer
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <div class="bg-white p-3 rounded box-shadow">
                    <p class="text-muted mb-0">
                      <span class="text-warning font-weight-bold">背景：</span>
                      某大型通讯公司企业大学金牌讲师，亚隆存在主义团体带领者，软培训导师，DISC认证讲师。通晓中国史学和国学，角度新颖而深刻，善于用人文角度解读管理和职场问题
                    </p>
                    <p class="text-muted mb-0 mt-3">
                      <span class="text-warning font-weight-bold">课程：</span>
                      《数字化时代颠覆性创新》《管理者自我成长》《教练式领导力》《打造高效团队执行力》《职场积极心态》《压力与情绪管理》《突破式沟通》《问题分析与解决》
                    </p>
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end row-->
    </div>
    <!--end container-->

    <!-- CTA START -->
    <section class="section">
      <div class="container">
        <div class="card bg-light rounded border-0 overflow-hidden">
          <div class="row no-gutters align-items-center">
            <div class="col-lg-6">
              <img src="images/youxue1.png" class="img-fluid" alt="" />
            </div>
            <!--end col-->

            <div class="col-lg-6 text-center">
              <div class="card-body section-title p-md-5">
                <h4 class="title mb-4 mt-4">青桐万里行-标杆企业游学</h4>
                <p class="text-muted mx-auto para-desc mb-0">
                  <span
                    class="text-warning font-weight-bold"
                    style="font-size: 20px"
                    >学习最佳直至成为最佳</span
                  >
                </p>
                <ul class="mt-4" style="line-height: 2">
                  <li>
                    青桐万里行（游学），倡导读万卷书之外，实地走进顶尖企业考察和学习，做到知行合一
                  </li>
                  <li>
                    青桐万里行以名企大厂的标杆实践为出发点，通过参观和深度课程，学习他人经营和组织经验
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
    </section>
    <!--end section-->
    <!-- CTA END -->

    <section class="section bg-light">
      <!-- End Client -->

      <!-- Start Blog -->
      <div class="container mt-30">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title pb-2">
              <h4 class="title mb-4">“青桐学堂” 过往培训案例</h4>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow">
              <div class="position-relative">
                <img
                  src="images/1.png"
                  class="card-img-top rounded-top"
                  alt="..."
                />
                <div class="overlay rounded-top bg-dark"></div>
              </div>
              <div class="card-body content">
                <h5>
                  <a
                    href="javascript:void(0)"
                    class="card-title title text-dark"
                    >《现场沟通技巧》</a
                  >
                </h5>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow">
              <div class="position-relative">
                <img
                  src="images/2.png"
                  class="card-img-top rounded-top"
                  alt="..."
                />
                <div class="overlay rounded-top bg-dark"></div>
              </div>
              <div class="card-body content">
                <h5>
                  <a
                    href="javascript:void(0)"
                    class="card-title title text-dark"
                    >《商业模式和商业计划书》</a
                  >
                </h5>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow">
              <div class="position-relative">
                <img
                  src="images/3.png"
                  class="card-img-top rounded-top"
                  alt="..."
                />
                <div class="overlay rounded-top bg-dark"></div>
              </div>
              <div class="card-body content">
                <h5>
                  <a
                    href="javascript:void(0)"
                    class="card-title title text-dark"
                    >《结构思维力-思考表达利器》</a
                  >
                </h5>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow">
              <div class="position-relative">
                <img
                  src="images/4.png"
                  class="card-img-top rounded-top"
                  alt="..."
                />
                <div class="overlay rounded-top bg-dark"></div>
              </div>
              <div class="card-body content">
                <h5>
                  <a
                    href="javascript:void(0)"
                    class="card-title title text-dark"
                    >《桌有成效有管理者》</a
                  >
                </h5>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow">
              <div class="position-relative">
                <img
                  src="images/5.png"
                  class="card-img-top rounded-top"
                  alt="..."
                />
                <div class="overlay rounded-top bg-dark"></div>
              </div>
              <div class="card-body content">
                <h5>
                  <a
                    href="javascript:void(0)"
                    class="card-title title text-dark"
                    >《以角色为中心的时间管理》</a
                  >
                </h5>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow">
              <div class="position-relative">
                <img
                  src="images/6.png"
                  class="card-img-top rounded-top"
                  alt="..."
                />
                <div class="overlay rounded-top bg-dark"></div>
              </div>
              <div class="card-body content">
                <h5>
                  <a
                    href="javascript:void(0)"
                    class="card-title title text-dark"
                    >《华为绩效薪酬实战营》</a
                  >
                </h5>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow">
              <div class="position-relative">
                <img
                  src="images/7.png"
                  class="card-img-top rounded-top"
                  alt="..."
                />
                <div class="overlay rounded-top bg-dark"></div>
              </div>
              <div class="card-body content">
                <h5>
                  <a
                    href="javascript:void(0)"
                    class="card-title title text-dark"
                    >《企业绩效管理》</a
                  >
                </h5>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow">
              <div class="position-relative">
                <img
                  src="images/8.png"
                  class="card-img-top rounded-top"
                  alt="..."
                />
                <div class="overlay rounded-top bg-dark"></div>
              </div>
              <div class="card-body content">
                <h5>
                  <a
                    href="javascript:void(0)"
                    class="card-title title text-dark"
                    >《HRBP业专家全攻略》</a
                  >
                </h5>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow">
              <div class="position-relative">
                <img
                  src="images/9.png"
                  class="card-img-top rounded-top"
                  alt="..."
                />
                <div class="overlay rounded-top bg-dark"></div>
              </div>
              <div class="card-body content">
                <h5>
                  <a
                    href="javascript:void(0)"
                    class="card-title title text-dark"
                    >《企业内训师提升训练》</a
                  >
                </h5>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow">
              <div class="position-relative">
                <img
                  src="images/10.png"
                  class="card-img-top rounded-top"
                  alt="..."
                />
                <div class="overlay rounded-top bg-dark"></div>
              </div>
              <div class="card-body content">
                <h5>
                  <a
                    href="javascript:void(0)"
                    class="card-title title text-dark"
                    >《从经营到效能》</a
                  >
                </h5>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow">
              <div class="position-relative">
                <img
                  src="images/11.png"
                  class="card-img-top rounded-top"
                  alt="..."
                />
                <div class="overlay rounded-top bg-dark"></div>
              </div>
              <div class="card-body content">
                <h5>
                  <a
                    href="javascript:void(0)"
                    class="card-title title text-dark"
                    >《产品经理之道》</a
                  >
                </h5>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow">
              <div class="position-relative">
                <img
                  src="images/12.png"
                  class="card-img-top rounded-top"
                  alt="..."
                />
                <div class="overlay rounded-top bg-dark"></div>
              </div>
              <div class="card-body content">
                <h5>
                  <a
                    href="javascript:void(0)"
                    class="card-title title text-dark"
                    >《从HR到战略性业务伙伴》</a
                  >
                </h5>
              </div>
            </div>
          </div>
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <!-- End Blog -->
    </section>
    <!--end section-->
    <!-- End Client And Blog -->

    <!--end section-->

    <div class="online-service">
      <ul>
        <li id="service-open">
          <div class="block">
            <span>
              <label>联系客服</label>
            </span>
          </div>
        </li>
        <li @click="showKf(1)">
          <span class="img-box">
            <img alt="咨询热线" src="../../public/images/icon/phone.png" />
          </span>
          <a class="intro-name" href="tel:15986805468">咨询热线</a>
          <div v-if="show1" class="service-fixed">
            <div class="info-box">
              售前热线：<a
                id="rightPhone"
                href="tel:15986805468"
                style="color: #39e09d"
                >15986805468</a
              >
            </div>
          </div>
        </li>
        <li @click="showKf(2)">
          <div class="img-box">
            <img alt="微信咨询" src="../../public/images/icon/wx.png" />
          </div>
          <span class="intro-name">微信咨询</span>
          <div v-if="show2" class="service-fixed">
            <div class="info-box">
              <img
                alt="微信咨询"
                id="rightQrCode"
                src="../../public/images/qywx.png"
              />
              <p>扫码立即咨询</p>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- End Form -->
    <Footer />
    <!-- Footer End -->
    <!-- <Switcher /> -->
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

<style>
.carousel-caption {
  width: auto;
  height: auto !important;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  position: unset !important;
}
/*在线客服*/
.online-service {
  position: fixed;
  bottom: 20%;
  right: 20px;
  z-index: 99999;
}

.online-service .hidden {
  visibility: hidden;
}

.online-service ul li {
  list-style: none;
  width: 64px;
  height: 64px;
  text-align: center;
  background: #ffffff;
  /*box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);*/
  border-radius: 8px;
  margin-bottom: 8px;
  position: relative;
  position: relative;
  cursor: pointer;
  box-shadow: 0px 0px 20px -4px rgba(0, 0, 0, 0.08);
  filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.11));
}

.online-service ul li:nth-child(1) {
  background: transparent;
}

.online-service ul li .img-box {
  padding-top: 13px;
  display: block;
}

.online-service ul li .intro-name {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #1e2221;
  display: block;
  padding: 6px 6px 0;
}

.online-service ul li img {
  margin: 0 auto;
}

.online-service ul li:nth-child(1) {
  margin-bottom: 16px;
}

.online-service ul li:nth-child(1) .block {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-image: url(../../public/images/icon/kf.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.online-service ul li:nth-child(1) span {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  width: 64px;
  height: 18px;

  background-color: #2eca8b;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;

  font-weight: 700;
  font-size: 12px;
  line-height: 18px;

  color: #ffffff;
  position: absolute;
  bottom: -10px;
}
.online-service ul li:nth-child(1) span label {
  font-size: 10px;
  font-weight: 600;
  transform: scale(0.9);
  display: inline-block;
  padding: 0 6px;
}
.online-service ul li:nth-child(1) a {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.online-service ul li:nth-child(2) img {
  width: 26px;
}

.online-service ul li:nth-child(3) img {
  width: 26px;
}

.online-service ul li:nth-child(4) img {
  width: 24px;
  height: 24px;
}

/**box*/
/**hover优化*/
.online-service ul li .box {
  background-color: transparent;
  display: block;
  width: 100px;
  height: 80px;
  position: absolute;
  left: -36px;
  top: 0;
}
.online-service ul li:nth-child(1) .box {
  left: -20px;
}
.online-service ul li .service-fixed {
  position: absolute;
  right: 60px;
  top: 3px;
}
.online-service ul li .info-box {
  position: relative;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  right: 16px;
  text-align: left;
  /*display: none;*/
  /*top: 7px;*/
}

.online-service ul li .info-box::after {
  content: "";
  display: block;
  width: 8px;
  height: 17px;
  position: absolute;
  right: -6px;
  top: 20px;
  background-image: url(../../public/images/icon/icon_box.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.online-service .on-box {
  /*top: 0;*/
  right: 16px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 0px 20px -4px rgba(0, 0, 0, 0.08);
  filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.11));
  position: relative;
}

.online-service .on-info-title {
  display: flex;
  justify-content: space-between;
}

.online-service .on-info-box {
  min-width: 168px;
  padding: 10px 27px 10px 14px;
  position: relative;
  cursor: pointer;
  z-index: 99;
}

.online-service .hide {
  display: none;
}

.online-service ul li:nth-child(2) .info-box {
  min-width: 171px;
  top: 0;
  padding: 19px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #2eca8b;
}

.online-service ul li:nth-child(2) .info-box label {
  cursor: pointer;
}

.online-service ul li:nth-child(3) .info-box {
  padding: 10px 10px 0;
  top: -32px;
}

.online-service ul li:nth-child(3) .info-box::after {
  top: 55px;
}

.online-service ul li:nth-child(3) .info-box img {
  width: 115px;
  height: 115px;
}

.online-service ul li:nth-child(3) .info-box p {
  left: 15px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #2eca8b;
  padding: 4px;
}

/**客服抖动*/
/**动态效果-抖动 */
.online-service .block {
  animation-name: shock;
  animation-iteration-count: infinite;
  animation-duration: 2000ms;
  animation-direction: normal;
  animation-timing-function: linear;
}

@keyframes shock {
  0% {
    transform-origin: bottom;
    transform: rotate(0);
  }
  2% {
    transform-origin: bottom;
    transform: rotate(-1.2deg);
  }
  4% {
    transform-origin: bottom;
    transform: rotate(-2deg);
  }
  6% {
    transform-origin: bottom;
    transform: rotate(-2.1deg);
  }
  8% {
    transform-origin: bottom;
    transform: rotate(-1.1deg);
  }
  10% {
    transform-origin: bottom;
    transform: rotate(5deg);
  }
  12% {
    transform-origin: bottom;
    transform: rotate(5.1deg);
  }
  14% {
    transform-origin: bottom;
    transform: rotate(3.9deg);
  }
  16% {
    transform-origin: bottom;
    transform: rotate(-3.1deg);
  }
  18% {
    transform-origin: bottom;
    transform: rotate(-4deg);
  }
  20% {
    transform-origin: bottom;
    transform: rotate(-3.6deg);
  }
  22% {
    transform-origin: bottom;
    transform: rotate(-1.9deg);
  }
  24% {
    transform-origin: bottom;
    transform: rotate(2.2deg);
  }
  26% {
    transform-origin: bottom;
    transform: rotate(3deg);
  }
  28% {
    transform-origin: bottom;
    transform: rotate(2.7deg);
  }
  30% {
    transform-origin: bottom;
    transform: rotate(1.5deg);
  }

  32% {
    transform-origin: bottom;
    transform: rotate(-0.2deg);
  }
  34% {
    transform-origin: bottom;
    transform: rotate(-1.6deg);
  }
  36% {
    transform-origin: bottom;
    transform: rotate(-2deg);
  }
  38% {
    transform-origin: bottom;
    transform: rotate(-1.7deg);
  }
  40% {
    transform-origin: bottom;
    transform: rotate(-1.1deg);
  }
  42% {
    transform-origin: bottom;
    transform: rotate(-0.4deg);
  }
  44% {
    transform-origin: bottom;
    transform: rotate(0deg);
  }
  46% {
    transform-origin: bottom;
    transform: rotate(0deg);
  }
  100% {
    transform-origin: bottom;
    transform: rotate(0deg);
  }
}

/**hover */
.online-service ul li:not(:first-child):hover {
  background: #ecf3ff;
}
.online-service ul li:nth-child(2):hover .service-fixed,
.online-service ul li:nth-child(3):hover .service-fixed {
  display: block;
}
.online-service ul li#service-open:hover .service-fixed {
  display: block;
}
.online-service ul li .service-fixed {
  display: none;
}
/**动态效果-抖动 */
.culture-block {
  /*animation-name: shock;*/
  animation-iteration-count: infinite;
  animation-duration: 2000ms;
  animation-direction: normal;
  animation-timing-function: linear;
}

.culture-block:hover {
  animation-name: none;
}

@keyframes shock {
  0% {
    transform-origin: bottom;
    transform: rotate(0);
  }
  2% {
    transform-origin: bottom;
    transform: rotate(-1.2deg);
  }
  4% {
    transform-origin: bottom;
    transform: rotate(-2deg);
  }
  6% {
    transform-origin: bottom;
    transform: rotate(-2.1deg);
  }
  8% {
    transform-origin: bottom;
    transform: rotate(-1.1deg);
  }
  10% {
    transform-origin: bottom;
    transform: rotate(5deg);
  }
  12% {
    transform-origin: bottom;
    transform: rotate(5.1deg);
  }
  14% {
    transform-origin: bottom;
    transform: rotate(3.9deg);
  }
  16% {
    transform-origin: bottom;
    transform: rotate(-3.1deg);
  }
  18% {
    transform-origin: bottom;
    transform: rotate(-4deg);
  }
  20% {
    transform-origin: bottom;
    transform: rotate(-3.6deg);
  }
  22% {
    transform-origin: bottom;
    transform: rotate(-1.9deg);
  }
  24% {
    transform-origin: bottom;
    transform: rotate(2.2deg);
  }
  26% {
    transform-origin: bottom;
    transform: rotate(3deg);
  }
  28% {
    transform-origin: bottom;
    transform: rotate(2.7deg);
  }
  30% {
    transform-origin: bottom;
    transform: rotate(1.5deg);
  }

  32% {
    transform-origin: bottom;
    transform: rotate(-0.2deg);
  }
  34% {
    transform-origin: bottom;
    transform: rotate(-1.6deg);
  }
  36% {
    transform-origin: bottom;
    transform: rotate(-2deg);
  }
  38% {
    transform-origin: bottom;
    transform: rotate(-1.7deg);
  }
  40% {
    transform-origin: bottom;
    transform: rotate(-1.1deg);
  }
  42% {
    transform-origin: bottom;
    transform: rotate(-0.4deg);
  }
  44% {
    transform-origin: bottom;
    transform: rotate(0deg);
  }
  46% {
    transform-origin: bottom;
    transform: rotate(0deg);
  }
  100% {
    transform-origin: bottom;
    transform: rotate(0deg);
  }
}
</style>
