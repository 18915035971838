<script>
import {
  ArrowUpIcon,
  MailIcon,
  PhoneIcon,
  UserIcon,
  ChevronRightIcon,
  PlayIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
} from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";
import countTo from "vue-count-to";

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";

/**
 * Index-course component
 */
export default {
  data() {
    return {
      slide: 0,
      start: "",
      end: "",
      interval: "",
      days: "",
      minutes: "",
      hours: "",
      seconds: "",
      starttime: "October 11, 2020 15:37:25",
      endtime: "Dec 31, 2020 16:37:25",
      // 轮播图图片
      bannerImg: [
        {
          img: "images/index-gu-bg.jpg",
        },
        {
          img: "images/index-gu-bg2.png",
        },
        {
          img: "images/index-gu-bg3.jpg",
        },
        {
          img: "images/index-gu-bg4.jpg",
        },
      ],
      // tab切换
      listTab: [
        {
          name: "招聘外包",
          icon: "images/index1.png",
        },
        {
          name: "人才外包",
          icon: "images/index3.png",
        },
        {
          name: "业务外包",
          icon: "images/index2.png",
        },
        {
          name: "自由职业者工作室",
          icon: "images/index4.png",
        },
      ],
      currentIndex: 0, //默认显示图片
      timer: null, //定时器
    };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    Carousel,
    Slide,
    ArrowUpIcon,
    countTo,
    MailIcon,
    PhoneIcon,
    UserIcon,
    ChevronRightIcon,
    PlayIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
  },
  computed: {
    // 计算当前图片位置
    current() {
      // 如果当前index等等于数组length-1 就return0 否则就当前index加1
      if (this.currentIndex == this.bannerImg.length - 1) {
        return 0;
      } else {
        return this.currentIndex + 1;
      }
    },
    current2() {
      // 如果当前index等等于数组length-1 就return0 否则就当前index加1
      if (this.currentIndex == 0) {
        return 0;
      } else {
        return this.currentIndex - 1;
      }
    },
  },
  mounted() {
    // 执行定时器
    this.autoPlay();
  },
  methods: {
    // 当点击后，当前索引等于点击的index
    clickTba(index) {
      console.log(index)
      this.currentIndex = index
    },
    // 定时器函数
    autoPlay() {
      this.timer = setInterval(() => {
        this.clickTba(this.current)
      }, 5000)
    },
    clickPrev() {
      console.log("prev", this.currentIndex);
      this.clickTba(this.current2);
    },
    clickNext() {
      console.log("next", this.currentIndex);
      this.clickTba(this.current);
    },
  },
};
</script>

<template>
  <div>
    <!-- <Navbar :is-white-navbar="true" /> -->
    <Navbar :nav-light="true" />

    <!-- Hero Start -->
    <section class="main-slider" style="position:relative;">
      <b-carousel fade id="carousel-1" v-model="slide" class="slides">
        <b-carousel-slide
          class="bg-slider d-flex align-items-center"
          style="background-image:url('images/coworking/bg08.jpg')"
        >
          <!-- <div class="bg-overlay"></div> -->
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 text-center">
                <div class="title-heading text-white mt-4">
                  <h1 class="display-4 title-dark font-weight-bold mb-3">
                    青桐人力科技平台
                  </h1>
                  <p class="mt-4 pt-2 para-desc para-dark mx-auto text-light" style="font-size: 22px;">
                    科技+资源驱动的新型人力资源服务商
                  </p>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
        </b-carousel-slide>
      </b-carousel>
    </section>
    <!--end section-->
    <section
      class="py-3 index-num"
      style="position: absolute;bottom: -0.1rem;z-index: 9;width:100%;background: rgba(0,0,0,1);"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="row justify-content-center">
              <div class="col-lg-2 col-md-4 col-6 pt-2">
                <div class="content">
                  <h6>
                    <a href="javascript:void(0);" class="title text-white"
                      >截至现在</a
                    >
                  </h6>
                  <h6 class="title text-white">青桐盛夏的服务数据</h6>
                </div>
              </div>
              <!--end col-->
              <!-- <div class="col-lg-2 col-md-4 col-6 pt-2">
                <div class="content text-center" style="line-height: 1;">
                  <p class="text-white" style="font-size:12px;">自由职业者</p>
                  <h6>
                    <a
                      href="javascript:void(0);"
                      class="title text-white"
                      style="font-size: 32px;"
                      ><countTo
                        :startVal="33"
                        :endVal="30"
                        :duration="5000"
                      ></countTo
                      >万+</a
                    >
                  </h6>
                </div>
              </div> -->
              <!--end col-->
              <div class="col-lg-2 col-md-4 col-6 pt-2">
                <div class="content text-center" style="line-height: 1;">
                  <p class="text-white" style="font-size:12px;">
                    全日制外包员工
                  </p>
                  <h6>
                    <a
                      href="javascript:void(0);"
                      class="title text-white"
                      style="font-size: 32px;"
                      ><countTo
                        :startVal="33"
                        :endVal="5000"
                        :duration="5000"
                      ></countTo
                      >+</a
                    >
                  </h6>
                </div>
              </div>
              <!--end col-->
              <div class="col-lg-2 col-md-4 col-6 pt-2">
                <div class="content text-center" style="line-height: 1;">
                  <p class="text-white" style="font-size:12px;">
                    人力资源产业基地
                  </p>
                  <h6>
                    <a
                      href="javascript:void(0);"
                      class="title text-white"
                      style="font-size: 32px;"
                      ><countTo
                        :startVal="33"
                        :endVal="10"
                        :duration="5000"
                      ></countTo
                      >+</a
                    >
                  </h6>
                </div>
              </div>
              <!--end col-->
              <div class="col-lg-2 col-md-4 col-6 pt-2">
                <div class="content text-center" style="line-height: 1;">
                  <p class="text-white" style="font-size:12px;">上市公司客户</p>
                  <h6>
                    <a
                      href="javascript:void(0);"
                      class="title text-white"
                      style="font-size: 32px;"
                    >
                      <countTo
                        :startVal="33"
                        :endVal="20"
                        :duration="5000"
                      ></countTo
                      >+</a
                    >
                  </h6>
                </div>
              </div>
              <!--end col-->
            </div>
          </div>
          <!--end col-->
        </div>
      </div>
      <!--end container-->
    </section>
    <!-- Hero End -->

     <section class="index-section2" style="padding-top: 30px;">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mt-4 pt-2">
              <h4 class="title">产品及服务</h4>
              <p class="text-muted">Products and services</p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>

      <div class="banner">
      <img :src="bannerImg[currentIndex].img" style="width: 100%;" />
      <div v-if="currentIndex == 0" class="banner-title">
        <h4>招聘外包服务产品</h4>
        <!-- <p>自由职业者为共享经济平台提供服务取得相应收入时，无法向平台<br>提供发票...</p> -->
        <router-link to="/index-landing-three" class="btn btn-sm btn-pills btn-success mt-3"
          >了解更多
        </router-link>
      </div>
      <div v-if="currentIndex == 2" class="banner-title">
        <h4>业务外包服务产品</h4>
        <!-- <p>自由职业者为共享经济平台提供服务取得相应收入时，无法向平台<br>提供发票...</p> -->
        <router-link to="/index-insurance" class="btn btn-sm btn-pills btn-success mt-3"
          >了解更多
        </router-link>
      </div>
      <div v-if="currentIndex == 1" class="banner-title">
        <h4>人才外包服务产品</h4>
        <!-- <p>自由职业者为共享经济平台提供服务取得相应收入时，无法向平台<br>提供发票...</p> -->
        <router-link to="/index-marketing" class="btn btn-sm btn-pills btn-success mt-3"
          >了解更多
        </router-link>
      </div>
      <div v-if="currentIndex == 3" class="banner-title">
        <h4>自由职业者工作室</h4>
        <!-- <p>自由职业者为共享经济平台提供服务取得相应收入时，无法向平台<br>提供发票...</p> -->
        <router-link to="/index-digital-agency" class="btn btn-sm btn-pills btn-success mt-3"
          >了解更多
        </router-link>
      </div>
      <div class="tab hidden">
        <ul>
          <li @click="clickPrev()">
            <div style="padding:15px 0;">
              <i class="mdi mdi-chevron-left text-white" style="font-size: 50px;"></i>
            </div>
          </li>
          <li
            v-for="(item, index) in listTab"
            :key="index"
            @click="clickTba(index)"
            :class="{ current: currentIndex == index }"
          >
            <div class="item">
              <div style="margin-right: 1rem;">
                {{ item.name }}<br />服务产品
              </div>
              <img :src="item.icon" />
            </div>
          </li>
          <li @click="clickNext()">
            <div>
              <!-- <i class="uil uil-angle-right-b" style="font-size: 50px;"></i> -->
              <i class="mdi mdi-chevron-right text-white" style="font-size: 50px;"></i>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- About Start -->
    <section class="bg-light" style="padding:60px 0 100px;">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4">
              <h4 class="title">公司简介</h4>
              <p class="text-muted">About us</p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <div class="row align-items-center mt-4 pt-2">
          <div class="col-lg-5 col-md-6 col-12">
            <img
              src="images/course/about.jpg"
              class="img-fluid shadow rounded"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="col-lg-7 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div class="section-title ml-lg-4">
              <h4 class="title mb-4">
                我们是谁 :
                <span class="text-primary">深圳青桐盛夏科技有限公司</span>
              </h4>
              <p class="text-muted">
                深圳青桐盛夏科技有限公司是专注于为高科技企业提供人才服务及用工成本优化服务的互联网科技企业，通过“科技+资源”的双驱动模式提升企业的招人用人效率，降低企业的用人成本 。目前，公司在全国已运营12家人力产业园，有2000+全日制外包员工，服务的上市公司客户超20家。              </p>
              <p class="text-muted">
                青桐是深圳青桐盛夏科技有限公司旗下的互联网人力科技平台(http: //www. 91qt.com/) ， 专注于垂直化行业场景，运用互联网科技手段为企业提供人才招募、人才外 包、业务外包、工薪（服务费）结算、人力成本降低等全流程人力服务。
              </p>
              <router-link to="/page-aboutus-two" class="btn btn-outline-primary mt-3"
                >了解更多
              </router-link>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--enc container-->
    </section>
    <!--end section-->

    <!-- Partners start -->
    <!-- <section class="py-5 border-bottom border-top">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-2 col-md-2 col-6 text-center">
            <img
              src="images/client/amazon.svg"
              class="avatar avatar-ex-sm"
              alt=""
            />
          </div>

          <div class="col-lg-2 col-md-2 col-6 text-center">
            <img
              src="images/client/google.svg"
              class="avatar avatar-ex-sm"
              alt=""
            />
          </div>

          <div class="col-lg-2 col-md-2 col-6 text-center mt-4 mt-sm-0">
            <img
              src="images/client/lenovo.svg"
              class="avatar avatar-ex-sm"
              alt=""
            />
          </div>

          <div class="col-lg-2 col-md-2 col-6 text-center mt-4 mt-sm-0">
            <img
              src="images/client/paypal.svg"
              class="avatar avatar-ex-sm"
              alt=""
            />
          </div>

          <div class="col-lg-2 col-md-2 col-6 text-center mt-4 mt-sm-0">
            <img
              src="images/client/shopify.svg"
              class="avatar avatar-ex-sm"
              alt=""
            />
          </div>

          <div class="col-lg-2 col-md-2 col-6 text-center mt-4 mt-sm-0">
            <img
              src="images/client/spotify.svg"
              class="avatar avatar-ex-sm"
              alt=""
            />
          </div>
        </div>
      </div>
    </section> -->
    <!--end section-->
    
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

<style scoped>
::v-deep .carousel-caption {
  display: contents;
  bottom: unset;
}

::v-deep .modal-content {
  background-color: transparent;
}
@media (max-width: 768px) {
  .index-section2 {
    padding-top: 350px !important;
  }
  .index-num {
    bottom: -5rem !important;
  }
  .hidden {
    visibility: hidden;
  }
}
.banner {
  width: 100%;
  position: relative;
}

.banner-title {
  position: absolute;
  top: 30%;
  left: 20%;
  color: #fff;
}

.tab {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
}
.tab ul {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}
.item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
}

.tab ul li {
  list-style: none;
  width: 20%;
  text-align: center;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.tab ul li:nth-last-child(1) {
  border-right: none;
}

.tab ul li img {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

.current {
  background: #32c2ec;
}
</style>
