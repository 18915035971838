<script>
import {
    ArrowUpIcon
} from 'vue-feather-icons';

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";

/**
 * Page-blog-list component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        Switcher,
        Footer,
        ArrowUpIcon
    }
}
</script>

<template>
<div>
    <!-- <Navbar :is-white-navbar="true" /> -->
    <Navbar :nav-light="true" />

    <!-- Hero Start -->
    <!-- bg-half-260 bg-primary d-table w-100 -->
    <section class="bg-half bg-primary d-table w-100" style="background: url('images/enterprise.png') center center;">
        <div class="bg-overlay"></div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level title-heading">
                        <h1 class="text-white title-dark"> 新闻资讯 </h1>
                        <div class="page-next">
                            <nav aria-label="breadcrumb" class="d-inline-block">
                                <ul class="breadcrumb bg-white rounded shadow mb-0">
                                    <li class="breadcrumb-item">
                                        <router-link to="/">首页</router-link>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">新闻资讯</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!-- Hero End -->

    <!-- Blog STart -->
    <section class="section">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 mb-4 pb-2">
                    <div class="card blog rounded border-0 shadow overflow-hidden">
                        <div class="position-relative">
                            <img src="images/blog/01.jpg" class="card-img-top" alt="...">
                            <div class="overlay rounded-top bg-dark"></div>
                        </div>
                        <div class="card-body content">
                            <h5><a href="javascript:void(0)" class="card-title title text-dark">2021年人力资源管理趋势的6个关键角色</a></h5>
                            <div class="post-meta d-flex justify-content-between mt-3">
                                <ul class="list-unstyled mb-0">
                                    <li class="list-inline-item mr-2 mb-0"><a href="javascript:void(0)" class="text-muted like"><i class="mdi mdi-calendar-check"></i> 2021-1-8</a></li>
                                    <!-- <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i class="mdi mdi-comment-outline mr-1"></i>08</a></li> -->
                                </ul>
                                <a href="https://mp.weixin.qq.com/s/Z-YVHycetmpSJ7Hg7UjKuA" target="_blank" class="text-muted readmore">查看更多 <i class="mdi mdi-chevron-right"></i></a>
                                <!-- <router-link to="/page-blog-detail-two?id=1" class="text-muted readmore">查看更多 <i class="mdi mdi-chevron-right"></i></router-link> -->
                            </div>
                        </div>
                        <div class="author">
                            <!-- <small class="text-light user d-block"><i class="mdi mdi-account"></i> CEO</small> -->
                            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> 2021-1-8</small>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-4 col-md-6 mb-4 pb-2">
                    <div class="card blog rounded border-0 shadow overflow-hidden">
                        <div class="position-relative">
                            <img src="images/blog/02.jpg" class="card-img-top" alt="...">
                            <div class="overlay rounded-top bg-dark"></div>
                        </div>
                        <div class="card-body content">
                            <h5><a href="javascript:void(0)" class="card-title title text-dark">人力资源事务转型为外包的关键问题与决策</a></h5>
                            <div class="post-meta d-flex justify-content-between mt-3">
                                <ul class="list-unstyled mb-0">
                                    <li class="list-inline-item mr-2 mb-0"><a href="javascript:void(0)" class="text-muted like"><i class="mdi mdi-calendar-check"></i> 2020-12-30</a></li>
                                    <!-- <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i class="mdi mdi-comment-outline mr-1"></i>08</a></li> -->
                                </ul>
                                <a href="https://mp.weixin.qq.com/s/RdUV5z8jG-pYMhTlg5qwcg" target="_blank" class="text-muted readmore">查看更多 <i class="mdi mdi-chevron-right"></i></a>
                                <!-- <router-link to="/page-blog-detail-two?id=2" class="text-muted readmore">查看更多 <i class="mdi mdi-chevron-right"></i></router-link> -->
                            </div>
                        </div>
                        <div class="author">
                            <!-- <small class="text-light user d-block"><i class="mdi mdi-account"></i> CEO</small> -->
                            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> 2020-12-30</small>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-4 col-md-6 mb-4 pb-2">
                    <div class="card blog rounded border-0 shadow overflow-hidden">
                        <div class="position-relative">
                            <img src="images/blog/03.jpg" class="card-img-top" alt="...">
                            <div class="overlay rounded-top bg-dark"></div>
                        </div>
                        <div class="card-body content">
                            <h5><a href="javascript:void(0)" class="card-title title text-dark">中国个税改革大幕拉开，薪税服务的未来在哪里</a></h5>
                            <div class="post-meta d-flex justify-content-between mt-3">
                                <ul class="list-unstyled mb-0">
                                    <li class="list-inline-item mr-2 mb-0"><a href="javascript:void(0)" class="text-muted like"><i class="mdi mdi-calendar-check"></i> 2019-1-23</a></li>
                                    <!-- <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i class="mdi mdi-comment-outline mr-1"></i>08</a></li> -->
                                </ul>
                                <a href="https://mp.weixin.qq.com/s/rf_Rur_YNiGeVwuh_XvODg" target="_blank" class="text-muted readmore">查看更多 <i class="mdi mdi-chevron-right"></i></a>
                                <!-- <router-link to="/page-blog-detail-two" class="text-muted readmore">查看更多 <i class="mdi mdi-chevron-right"></i></router-link> -->
                            </div>
                        </div>
                        <div class="author">
                            <!-- <small class="text-light user d-block"><i class="mdi mdi-account"></i> CEO</small> -->
                            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> 2019-1-23</small>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-4 col-md-6 mb-4 pb-2">
                    <div class="card blog rounded border-0 shadow overflow-hidden">
                        <div class="position-relative">
                            <img src="images/blog/04.jpg" class="card-img-top" alt="...">
                            <div class="overlay rounded-top bg-dark"></div>
                        </div>
                        <div class="card-body content">
                            <h5><a href="javascript:void(0)" class="card-title title text-dark">人力资源数字化让每个员工都被赋能</a></h5>
                            <div class="post-meta d-flex justify-content-between mt-3">
                                <ul class="list-unstyled mb-0">
                                    <li class="list-inline-item mr-2 mb-0"><a href="javascript:void(0)" class="text-muted like"><i class="mdi mdi-calendar-check"></i> 2020-12-29</a></li>
                                    <!-- <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i class="mdi mdi-comment-outline mr-1"></i>08</a></li> -->
                                </ul>
                                <a href="https://mp.weixin.qq.com/s/8wzKtSWr_dmM7YD4WUYPLw" target="_blank" class="text-muted readmore">查看更多 <i class="mdi mdi-chevron-right"></i></a>
                                
                                <!-- <router-link to="/page-blog-detail-two" class="text-muted readmore">查看更多 <i class="mdi mdi-chevron-right"></i></router-link> -->
                            </div>
                        </div>
                        <div class="author">
                            <!-- <small class="text-light user d-block"><i class="mdi mdi-account"></i> CEO</small> -->
                            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> 2020-12-29</small>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-4 col-md-6 mb-4 pb-2">
                    <div class="card blog rounded border-0 shadow overflow-hidden">
                        <div class="position-relative">
                            <img src="images/blog/05.jpg" class="card-img-top" alt="...">
                            <div class="overlay rounded-top bg-dark"></div>
                        </div>
                        <div class="card-body content">
                            <h5><a href="javascript:void(0)" class="card-title title text-dark">网红主播如何合规节税？这样做能省很多钱！</a></h5>
                            <div class="post-meta d-flex justify-content-between mt-3">
                                <ul class="list-unstyled mb-0">
                                    <li class="list-inline-item mr-2 mb-0"><a href="javascript:void(0)" class="text-muted like"><i class="mdi mdi-calendar-check"></i> 2019-12-28</a></li>
                                    <!-- <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i class="mdi mdi-comment-outline mr-1"></i>08</a></li> -->
                                </ul>
                                <a href="https://mp.weixin.qq.com/s/utyp9u8x1j6ARZ8xwO54Cw" target="_blank" class="text-muted readmore">查看更多 <i class="mdi mdi-chevron-right"></i></a>
                                <!-- <router-link to="/page-blog-detail-two" class="text-muted readmore">查看更多 <i class="mdi mdi-chevron-right"></i></router-link> -->
                            </div>
                        </div>
                        <div class="author">
                            <!-- <small class="text-light user d-block"><i class="mdi mdi-account"></i> CEO</small> -->
                            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> 2019-12-28</small>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <!-- PAGINATION START -->
                <!-- <div class="col-12">
                    <ul class="pagination justify-content-center mb-0">
                        <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a></li>
                        <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
                        <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                        <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                        <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
                    </ul>
                </div> -->
                <!--end col-->
                <!-- PAGINATION END -->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Blog End -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>
