<script>
import {
    ArrowUpIcon,
    ChevronRightIcon,
    ArrowRightIcon
} from 'vue-feather-icons';

import {
    Carousel,
    Slide
} from 'vue-carousel';

import {
    VueTyper
} from 'vue-typer'

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";

/**
 * Index-landing-three component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        Switcher,
        Footer,
        ArrowUpIcon,
        ChevronRightIcon,
        ArrowRightIcon,
        Carousel,
        Slide,
        'vue-typer': VueTyper
    }
}
</script>

<template>
<div>
    <Navbar :nav-light="true" />
    <!-- Hero Start -->
    <section class="bg-half-260 bg-primary d-table w-100" style="background: url('images/bg.png') center;">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="title-heading vue-typer-color">
                        <h1 class="heading text-white mb-4">您是否面临以下招聘难题？ <vue-typer :text='["短期内有较大需求", "暂无内部招聘部门", "自身招聘效率不达预期", "人员流失较大", "新项目团队招聘压力大", "内部招聘受限"]'></vue-typer>
                        </h1>
                        <p class="para-desc mx-auto text-white-50">赶快联系我们，让我们来帮您解决.</p>
                        <div class="mt-4 pt-2">
                            <router-link to="/helpcenter-support-request" class="btn btn-light"
                                ><i class="mdi mdi-email"></i> 取得联系
                                </router-link>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!-- Hero End -->

    <!-- FEATURES START -->
    <section class="section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10">
                    <div class="features-absolute">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="media features p-4 bg-white shadow rounded">
                                    <div class="icons m-0 rounded h2 text-primary text-center px-3">
                                        <!-- <i class="uil uil-arrow-growth"></i> -->
                                        <img src="images/zp1.png" alt="" style="height: 60px;">
                                    </div>
                                    <div class="content ml-4">
                                        <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">短期内有较大需求</a></h5>
                                        <p class="text-muted mb-0">为确保需生产，销售需要，短期内有较大的量职位需求.</p>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div class="media features p-4 bg-white shadow rounded">
                                    <div class="icons m-0 rounded h2 text-primary text-center px-3">
                                        <img src="images/zp2.png" alt="" style="height: 60px;">
                                    </div>
                                    <div class="content ml-4">
                                        <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">暂无内部招聘部门</a></h5>
                                        <p class="text-muted mb-0">公司内部暂无设立招聘部门，但又急需招聘人才.</p>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-md-6 mt-4 pt-2">
                                <div class="media features p-4 bg-white shadow rounded">
                                    <div class="icons m-0 rounded h2 text-primary text-center px-3">
                                        <img src="images/zp3.png" alt="" style="height: 60px;">
                                    </div>
                                    <div class="content ml-4">
                                        <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">自身招聘效率不达预期</a></h5>
                                        <p class="text-muted mb-0">自身招聘渠道费时费力所招到人才又不达标.</p>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-md-6 mt-4 pt-2">
                                <div class="media features p-4 bg-white shadow rounded">
                                    <div class="icons m-0 rounded h2 text-primary text-center px-3">
                                        <img src="images/zp4.png" alt="" style="height: 60px;">
                                    </div>
                                    <div class="content ml-4">
                                        <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">人员流失较大</a></h5>
                                        <p class="text-muted mb-0">在一定时间内人员流动较大，急需补充人员交接.</p>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-md-6 mt-4 pt-2">
                                <div class="media features p-4 bg-white shadow rounded">
                                    <div class="icons m-0 rounded h2 text-primary text-center px-3">
                                        <img src="images/zp5.png" alt="" style="height: 60px;">
                                    </div>
                                    <div class="content ml-4">
                                        <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">新项目团队招聘压力大</a></h5>
                                        <p class="text-muted mb-0">新建项目，内部团队人手不足，为保项目进展，招聘压力大.</p>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-md-6 mt-4 pt-2">
                                <div class="media features p-4 bg-white shadow rounded">
                                    <div class="icons m-0 rounded h2 text-primary text-center px-3">
                                        <img src="images/zp6.png" alt="" style="height: 60px;">
                                    </div>
                                    <div class="content ml-4">
                                        <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">内部招聘受限</a></h5>
                                        <p class="text-muted mb-0">新开设工厂或运营中心，短期内需要人员尽快到岗，但内部招聘名额受限.</p>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                        </div>
                    </div>
                </div>
            </div>
            <!--end row-->
        </div>
        <!--end container-->
<div class="container mt-100 mb-4 pb-4">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="section-title mb-4 pb-2">
                        <h4 class="title mb-4">交付岗位示例</h4>
                        <p class="text-muted para-desc mb-0 mx-auto">我们可以提供十几种交付岗位</p>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

            <div class="row">
                <div class="col-lg-3 col-md-4 mt-4 pt-2">
                    <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                        <span class="h1 icon2 text-primary">
                            <!-- <i class="uil uil-users-alt"></i> -->
                            <img src="images/zp7.png" alt="" style="height: 60px;">
                        </span>
                        <div class="card-body p-0 content">
                            <h5>客服</h5>
                            <p class="para text-muted mb-0">200人/月</p>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-3 col-md-4 mt-4 pt-2">
                    <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                        <span class="h1 icon2 text-primary">
                           <img src="images/zp8.png" alt="" style="height: 60px;">
                        </span>
                        <div class="card-body p-0 content">
                            <h5>数据标注</h5>
                            <p class="para text-muted mb-0">200人/月</p>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-3 col-md-4 mt-4 pt-2">
                    <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                        <span class="h1 icon2 text-primary">
                            <img src="images/zp9.png" alt="" style="height: 60px;">
                        </span>
                        <div class="card-body p-0 content">
                            <h5>技术人员</h5>
                            <p class="para text-muted mb-0">200人/月</p>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-3 col-md-4 mt-4 pt-2">
                    <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                        <span class="h1 icon2 text-primary">
                            <img src="images/zp10.png" alt="" style="height: 60px;">
                        </span>
                        <div class="card-body p-0 content">
                            <h5>运营推广</h5>
                            <p class="para text-muted mb-0">200人/月</p>
                        </div>
                    </div>
                </div>
                <!--end col-->


            </div>
            <!--end row-->
        </div>

    </section>
    <!--end section-->

  
    <!-- <section class="section bg-light">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-6">
                    <img src="images/zp11.png" class="img-fluid" alt="">
                </div>
                <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="section-title ml-lg-5">
                        <h4 class="title mb-4">服务产品</h4>
                        <p class="text-muted">青桐盛夏凭借多年积累的数据以及对行业的洞察，制定的招聘外包解决方案，可
以让企业对实现招聘目标更有信心，同时提升候选人质量。我们备受业界推崇的
RPO解决方案整合了招聘顾问的专长及更新的招聘技术，从而简化招聘流程，优
化组织和候选人的体验，并提升招聘质量，通过庞大的数据资源、创新的资源整
合策略、覆盖全球的顾问网络，我们的解决方案能为企业找到杰出人才，同时降
低招聘成本，高度契合公司战略，并尽可能消除招聘失误带来的高昂代价.</p>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <!--end section-->

    <!-- Price Start -->
    <section class="section">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="section-title">
                        <h4 class="title mb-4">服务介绍</h4>
                        <p class="text-muted">始终专注于TMT行业，人才简历库资源积累丰富，同时注重自建团队和合作伙伴两条招聘渠道，承诺3个2服务，即，2小时内响应，2天内提供简历，2周内到岗。</p>
                        <p class="text-muted">我司拥有经验丰富的全职招聘人员50余人，可承接如前后端程序员、美术设计师、测试工程师、产品运营、售前售后客服、安全巡查员等岗位的全流程招聘服务。</p>
                        <p class="text-muted">同时，我司和全国20余所大专、技工院校以及10余所全日制本科院校已达成深度合作，建立了5个产学教基地，2所本科实训基地，累计输出全职员工500余人，产教融合200余人，兼职2000余人。</p>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-6 col-md-6 order-1 order-md-2">
                    <img src="images/zp11.png" class="img-fluid" alt="">
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>

    <!-- Testi Start -->
    <section class="section bg-light">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="section-title mb-4 pb-2">
                        <h4 class="title mb-2">服务优势</h4>
                        <!-- <p class="text-muted para-desc mx-auto mb-0">行业垂直、岗位聚焦、社会招聘+基地培养、大批量+多区域.</p> -->
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 mt-4">
                    <carousel id="customer-testi" class="owl-carousel owl-theme" dir="ltr" :per-page="4">
                        <Slide>
                            <div class="card text-center border-0 shadow rounded m-2">
                                <div class="card-body">
                                    <img src="images/004-radar-1.png" alt="" style="height: 60px;">
                                    <h6 class="text-primary mt-4">行业垂直+岗位聚焦</h6>
                                    <p class="text-muted">24小时快速响应客户需求，最快3天启动</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide>
                            <div class="card text-center border-0 shadow rounded m-2">
                                <div class="card-body">
                                    <img src="images/005-radar-2.png" alt="" style="height: 60px;">
                                    <h6 class="text-primary mt-4">社会招募+基地培养</h6>
                                    <p class="text-muted">线下线上结合，有效应对波风波谷降低成本</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide>
                            <div class="card text-center border-0 shadow rounded m-2">
                                <div class="card-body">
                                    <img src="images/001-circle.png" alt="" style="height: 60px;">
                                    <h6 class="text-primary mt-4">大批量+多区域</h6>
                                    <p class="text-muted">全国跨区域联动、专业团队高效项目制动作</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide>
                            <div class="card text-center border-0 shadow rounded m-2">
                                <div class="card-body">
                                    <img src="images/006-pay.png" alt="" style="height: 60px;">
                                    <!-- <img src="images/product/3.png" class="img-fluid avatar  mx-auto" alt=""> -->
                                    <h6 class="text-primary mt-4">按效果付费+到岗付费</h6>
                                    <p class="text-muted">深度理解客户需求、精准行为标签识别</p>
                                </div>
                            </div>
                        </Slide>
                    </carousel>
                </div>
            </div>
        </div>
    </section>
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>

<style>
.modal-content {
    background-color: transparent;
}
.vue-typer-color .vue-typer .custom.char.typed {
    color: #fff;
}
</style>
