<script>
import { VueTyper } from "vue-typer";

import countTo from "vue-count-to";
import {
  ArrowUpIcon,
  UserIcon,
  MailIcon,
  PhoneIcon,
  PlayIcon,
} from "vue-feather-icons";

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
/**
 * Index-coworking component
 */
export default {
  data() {
    return {
      scrollShow: true,
      show1: false,
      show2: false,
      slide: 0,
      caseList: [
        {
          image: "images/work/1.jpg",
          list: [
            {
              title: "心态问题",
              text: "抱怨和借口一大堆，缺乏向内思考",
            },
            {
              title: "安于现状",
              text: "抱怨和借口一大堆，缺乏向内思考",
            },
            {
              title: "领导力缺乏",
              text: "抱怨和借口一大堆，缺乏向内思考",
            },
            {
              title: "领导力问题",
              text: "抱怨和借口一大堆，缺乏向内思考",
            },
          ],
          variant: "primary",
          type: "领导力问题",
        },
        {
          image: "images/work/2.jpg",
          list: [
            {
              title: "缺乏战略",
              text: "缺乏公司战略和系统电目标，想到什么做什么，到头来效果不理想",
            },
            {
              title: "缺乏计划",
              text: "有目标无计划或计划缺乏有效性，枪声一响计划作废一半",
            },
            {
              title: "缺乏预算",
              text: "财务/成本/费用无预算控制，没关注投入产出比",
            },
          ],
          variant: "warning",
          type: "目标问题",
        },
        {
          image: "images/work/3.jpg",
          list: [
            {
              title: "缺乏团队力量",
              text: "没有形成整体力量，个人天天加班，四处救火，同样的问题重复发生",
            },
            {
              title: "岗位职责不明确",
              text: "组织架构不清晰，合适人才没有发展最大效能，内部不能有效协同",
            },
            {
              title: "缺乏人才培养计划",
              text: "人才梯队无培养，得力干将缺乏，凡事自己上",
            },
          ],
          variant: "success",
          type: "团队问题",
        },
        {
          image: "images/work/4.jpg",
          list: [
            {
              title: "缺乏流程工具化",
              text: "没有自作手册，缺乏指导书、模版、检查清单、案例库，换人换个做法",
            },
            {
              title: "缺乏过程控制机制",
              text: "过程缺乏控制机制，目标过程两张皮，任其发展，秋后算账又头疼",
            },
            {
              title: "缺乏有效考核手段",
              text: "缺乏一套有针对性的考核奖励机制",
            },
          ],
          variant: "info",
          type: "机制问题",
        },
        {
          image: "images/work/5.jpg",
          list: [
            {
              title: "缺乏对标",
              text: "对外不对标，自我感觉良好，缺乏参照对手",
            },
            {
              title: "缺乏落实",
              text: "参加了培训，学习了工具和方法，但回到工作中就忘记，还是不会做",
            },
            {
              title: "缺乏复盘",
              text: "对内缺乏复盘和改进，优秀经验缺乏复制，被同一个石头砸两次",
            },
          ],
          variant: "primary",
          type: "学习问题",
        },
      ],
      filterCategory: "all",
      visible: false,
    };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    ArrowUpIcon,
    UserIcon,
    MailIcon,
    PhoneIcon,
    PlayIcon,
    PlayIcon,
    "vue-typer": VueTyper,
    countTo,
  },
  computed: {
    filteredData: function () {
      if (this.filterCategory === "all") {
        return this.caseList;
      } else {
        return this.caseList.filter((x) => x.type === this.filterCategory);
      }
    },
  },
  methods: {
    updateFilter(filterName) {
      this.filterCategory = filterName;
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    showKf: function (e) {
      // this.show1 = !this.show1;
      console.log(e);
      if (e == 1) {
        this.show1 = !this.show1;
        this.show2 = false;
      }
      if (e == 2) {
        this.show2 = !this.show2;
        this.show1 = false;
      }
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      console.log("滚动距离", scrollTop);
      if (scrollTop > 600) {
        this.scrollShow = false;
      } else {
        this.scrollShow = true;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<template>
<div>
    <Navbar :nav-light="true" />
    <!-- Hero Start -->
    <section class="bg-half-260 bg-primary d-table w-100" style="background: url('images/enterprise.png') center center;">
        <div class="bg-overlay"></div>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="title-heading text-center mt-4">
                        <h1 class="display-4 title-dark font-weight-bold text-white mb-3">自由职业工作室</h1>
                        <p class="para-desc mx-auto text-white-50">赶快联系我们，我们将会以安全合规、有据可循为基本原则，解决自由职业者与雇主之间对接难成本高的痛点难题，您的问题让我们来解决.</p>
                        <!-- <div class="mt-4 pt-2">
                            <a href="javascript: void(0);" class="btn btn-primary"><i class="mdi mdi-email"></i> 联系我们</a>
                        </div> -->
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!--Shape End-->
    <section class="section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-5 col-12 mr-4">
            <div class="row align-items-center">
              <div class="col-lg-10 col-12">
                <div class="p-0">
                  <img
                    src="images/illustrator/Marketing_strategy_SVG.svg"
                    class="img-fluid mx-auto d-block"
                    alt="work-image"
                  />
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end col-->

          <div class="col-lg-6 col-md-6 mt-4 mt-lg-0 pt-lg-0">
            <div class="ml-lg-4">
              <div class="section-title mb-4">
                <h4 class="title mb-4">工作室（主播）业务介绍</h4>
              </div>
              <ol class="mt-3 text-muted" style="padding-left: 16px">
                <li class="mt-2">
                  <h6 style="font-size: 15px; line-height: 1.7">
                    针对中高收入自由职业者主播（比如月收入2万以上），注册个体工商户
                  </h6>
                </li>
                <li class="mt-2">
                  <h6 style="font-size: 15px; line-height: 1.7">
                    采用查账征收，当前政策1%增值税普票或专票，经营所得个税5-35%
                  </h6>
                </li>
                <li class="mt-2">
                  <h6 style="font-size: 15px; line-height: 1.7">
                    全程托管，小程序电子签约付费，四要素在线提交，3-5个工作日办理完成
                  </h6>
                </li>
                <li class="mt-2">
                  <h6 style="font-size: 15px; line-height: 1.7">
                    支持主播在小程序提交成本发票，在线审核发票，完税证明在线查看
                  </h6>
                </li>
                <li class="mt-2">
                  <h6 style="font-size: 15px; line-height: 1.7">
                    系统支持与腾讯对接，实时对接主播发放数据，自动计算成本票计税
                  </h6>
                </li>
                <li class="mt-2">
                  <h6 style="font-size: 15px; line-height: 1.7">
                    支持5园区同时提供主播个体户服务，专业服务团队，具备负载均衡能力
                  </h6>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <!--end row-->
      </div>
      <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">服务对象</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                多业务模块组合，服务场景支持更灵活.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
            <div class="features text-center pt-3 pb-3">
              <img src="images/icon/wifi.svg" height="50" alt="" />
              <div class="content mt-3">
                <h4 class="title-2">网络推广</h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
            <div class="features text-center pt-3 pb-3">
              <img src="images/icon/printer.svg" height="50" alt="" />
              <div class="content mt-3">
                <h4 class="title-2">在互讲师</h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
            <div class="features text-center pt-3 pb-3">
              <img src="images/icon/24-hours.svg" height="50" alt="" />
              <div class="content mt-3">
                <h4 class="title-2">网络主播</h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
            <div class="features text-center pt-3 pb-3">
              <img src="images/icon/serving-dish.svg" height="50" alt="" />
              <div class="content mt-3">
                <h4 class="title-2">外卖小哥</h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
            <div class="features text-center pt-3 pb-3">
              <img src="images/icon/mail2.svg" height="50" alt="" />
              <div class="content mt-3">
                <h4 class="title-2">在线设计师</h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
            <div class="features text-center pt-3 pb-3">
              <img src="images/icon/calendar_b.svg" height="50" alt="" />
              <div class="content mt-3">
                <h4 class="title-2">专车 & 顺风车</h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
            <div class="features text-center pt-3 pb-3">
              <img src="images/icon/coffee-cup.svg" height="50" alt="" />
              <div class="content mt-3">
                <h4 class="title-2">在线医疗</h4>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-6 mt-4 pt-2">
            <div class="features text-center pt-3 pb-3">
              <img src="images/icon/question.svg" height="50" alt="" />
              <div class="content mt-3">
                <h4 class="title-2">其他行业</h4>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <section class="section">
      <div class="container">
        <div class="row align-items-center" id="counter">
          <div class="col-md-6">
            <img src="images/yw1.png" class="img-fluid" style="box-shadow: 0 3px 5px 0 rgb(173 191 250 / 30%);" alt="img" />
          </div>
          <!--end col-->

          <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div class="ml-lg-4">
              <div class="section-title">
                <h4 class="title mb-4">业务逻辑及注册流程</h4>
                <ol class="mt-3 text-muted" style="padding-left: 16px">
                <li class="mt-2">
                  <h6 style="font-size: 15px; line-height: 1.7">
                    提供法人姓名、身份证号、手机号、工作室名称，提供三个默认姓名为主名、身份正反面照片。（小程序提交，签约代账合同）
                  </h6>
                </li>
                <!-- <li class="mt-2">
                  <h6 style="font-size: 15px; line-height: 1.7">
                    代办员向工商系统提交材料。
                  </h6>
                </li> -->
                <li class="mt-2">
                  <h6 style="font-size: 15px; line-height: 1.7">
                    法人在工商app上实名认证一下
                  </h6>
                </li>
                <li class="mt-2">
                  <h6 style="font-size: 15px; line-height: 1.7">
                    法人在当地税局app做设立的电子签章，网上认证下载，才能出纸质执照
                  </h6>
                </li>
                <li class="mt-2">
                  <h6 style="font-size: 15px; line-height: 1.7">
                    提供需要一个租赁合同（盖章，签字）
                  </h6>
                </li>
                <li class="mt-2">
                  <h6 style="font-size: 15px; line-height: 1.7">
                    刻章委托书，签字
                  </h6>
                </li>
                <li class="mt-2">
                  <h6 style="font-size: 15px; line-height: 1.7">
                    法人在当地税务要做个税务实名认证
                  </h6>
                </li>
                <li class="mt-2">
                  <h6 style="font-size: 15px; line-height: 1.7">
                    经办人现场办理还需要法人扫码登陆一次或者两个验证码。（两次沟通一次实名，一次扫码）
                  </h6>
                </li>
              </ol>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>

      <div class="container mt-60">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">我们的优势 ?</h4>
              <p class="text-muted para-desc mb-0 mx-auto">
                我们拥有“互联网+灵活用工”领域的灵活用工撮合、发薪、工作室注册一站式服务平台，致力于为企业、从业者以及人力资源公司提供高效实时信息交互服务，搭配电子签约、税务代缴、实时分账系统实现远程签约、一键完税、实时发薪.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-lg-3 col-md-4 mt-4 pt-2">
            <div
              class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
            >
              <span class="h1 icon2 text-primary">
                <img src="images/zy1.png" alt="" style="height: 60px;">
              </span>
              <div class="card-body p-0 content">
                <h5>专业高效</h5>
                <p class="para text-muted mb-0">
                  1 V 1 专业顾问，为企业提供场景化解决方案.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 mt-4 pt-2">
            <div
              class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
            >
              <span class="h1 icon2 text-primary">
                <img src="images/zy2.png" alt="" style="height: 60px;">
              </span>
              <div class="card-body p-0 content">
                <h5>合法合规</h5>
                <p class="para text-muted mb-0">
                  真实业务留存实名认证，依法纳税.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 mt-4 pt-2">
            <div
              class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
            >
              <span class="h1 icon2 text-primary">
                <img src="images/zy3.png" alt="" style="height: 60px;">
              </span>
              <div class="card-body p-0 content">
                <h5>专业团队</h5>
                <p class="para text-muted mb-0">
                  致力于行业多年，积累大量经验.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 mt-4 pt-2">
            <div
              class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
            >
              <span class="h1 icon2 text-primary">
                <img src="images/zy4.png" alt="" style="height: 60px;">
              </span>
              <div class="card-body p-0 content">
                <h5>定制方案</h5>
                <p class="para text-muted mb-0">
                  从客户实际出发，定制客户专属解决方案.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">合作伙伴</h4>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-lg-12 col-md-12 mt-4 pt-2">
            <div style="background-color: #2f55d4!important;"
              class="card rounded p-4 bg-light text-center position-relative overflow-hidden border-0"
            >
              <span class="h1 icon2 text-primary">
                <img src="images/hz.png" alt="" style="width: 100%;">
              </span>
            </div>
          </div>
        </div>
        <!--end row-->
      </div>
    </section>
  

    <!-- Shape Start -->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>

    <!-- Shape Start -->
    <div class="position-relative">
        <div class="shape overflow-hidden text-footer">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <div class="online-service">
      <ul>
        <li id="service-open">
          <div class="block">
            <span>
              <label>联系客服</label>
            </span>
          </div>
        </li>
        <li @click="showKf(1)">
          <span class="img-box">
            <img alt="咨询热线" src="../../public/images/icon/phone.png" />
          </span>
          <a class="intro-name" href="tel:15986805468">咨询热线</a>
          <div v-if="show1" class="service-fixed">
            <div class="info-box">
              售前热线：<a
                id="rightPhone"
                href="tel:15986805468"
                style="color: #39e09d"
                >15986805468</a
              >
            </div>
          </div>
        </li>
        <li @click="showKf(2)">
          <div class="img-box">
            <img alt="微信咨询" src="../../public/images/icon/wx.png" />
          </div>
          <span class="intro-name">微信咨询</span>
          <div v-if="show2" class="service-fixed">
            <div class="info-box">
              <img
                alt="微信咨询"
                id="rightQrCode"
                src="../../public/images/qywx.png"
              />
              <p>扫码立即咨询</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>
<style>
/*在线客服*/
.online-service {
  position: fixed;
  bottom: 20%;
  right: 20px;
  z-index: 99999;
}

.online-service .hidden {
  visibility: hidden;
}

.online-service ul li {
  list-style: none;
  width: 64px;
  height: 64px;
  text-align: center;
  background: #ffffff;
  /*box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);*/
  border-radius: 8px;
  margin-bottom: 8px;
  position: relative;
  position: relative;
  cursor: pointer;
  box-shadow: 0px 0px 20px -4px rgba(0, 0, 0, 0.08);
  filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.11));
}

.online-service ul li:nth-child(1) {
  background: transparent;
}

.online-service ul li .img-box {
  padding-top: 13px;
  display: block;
}

.online-service ul li .intro-name {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #1e2221;
  display: block;
  padding: 6px 6px 0;
}

.online-service ul li img {
  margin: 0 auto;
}

.online-service ul li:nth-child(1) {
  margin-bottom: 16px;
}

.online-service ul li:nth-child(1) .block {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-image: url(../../public/images/icon/kf.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.online-service ul li:nth-child(1) span {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  width: 64px;
  height: 18px;

  background-color: #2eca8b;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;

  font-weight: 700;
  font-size: 12px;
  line-height: 18px;

  color: #ffffff;
  position: absolute;
  bottom: -10px;
}
.online-service ul li:nth-child(1) span label {
  font-size: 10px;
  font-weight: 600;
  transform: scale(0.9);
  display: inline-block;
  padding: 0 6px;
}
.online-service ul li:nth-child(1) a {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.online-service ul li:nth-child(2) img {
  width: 26px;
}

.online-service ul li:nth-child(3) img {
  width: 26px;
}

.online-service ul li:nth-child(4) img {
  width: 24px;
  height: 24px;
}

/**box*/
/**hover优化*/
.online-service ul li .box {
  background-color: transparent;
  display: block;
  width: 100px;
  height: 80px;
  position: absolute;
  left: -36px;
  top: 0;
}
.online-service ul li:nth-child(1) .box {
  left: -20px;
}
.online-service ul li .service-fixed {
  position: absolute;
  right: 60px;
  top: 3px;
}
.online-service ul li .info-box {
  position: relative;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  right: 16px;
  text-align: left;
  /*display: none;*/
  /*top: 7px;*/
}

.online-service ul li .info-box::after {
  content: "";
  display: block;
  width: 8px;
  height: 17px;
  position: absolute;
  right: -6px;
  top: 20px;
  background-image: url(../../public/images/icon/icon_box.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.online-service .on-box {
  /*top: 0;*/
  right: 16px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 0px 20px -4px rgba(0, 0, 0, 0.08);
  filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.11));
  position: relative;
}

.online-service .on-info-title {
  display: flex;
  justify-content: space-between;
}

.online-service .on-info-box {
  min-width: 168px;
  padding: 10px 27px 10px 14px;
  position: relative;
  cursor: pointer;
  z-index: 99;
}

.online-service .hide {
  display: none;
}

.online-service ul li:nth-child(2) .info-box {
  min-width: 171px;
  top: 0;
  padding: 19px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #2eca8b;
}

.online-service ul li:nth-child(2) .info-box label {
  cursor: pointer;
}

.online-service ul li:nth-child(3) .info-box {
  padding: 10px 10px 0;
  top: -32px;
}

.online-service ul li:nth-child(3) .info-box::after {
  top: 55px;
}

.online-service ul li:nth-child(3) .info-box img {
  width: 115px;
  height: 115px;
}

.online-service ul li:nth-child(3) .info-box p {
  left: 15px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #2eca8b;
  padding: 4px;
}

/**客服抖动*/
/**动态效果-抖动 */
.online-service .block {
  animation-name: shock;
  animation-iteration-count: infinite;
  animation-duration: 2000ms;
  animation-direction: normal;
  animation-timing-function: linear;
}

@keyframes shock {
  0% {
    transform-origin: bottom;
    transform: rotate(0);
  }
  2% {
    transform-origin: bottom;
    transform: rotate(-1.2deg);
  }
  4% {
    transform-origin: bottom;
    transform: rotate(-2deg);
  }
  6% {
    transform-origin: bottom;
    transform: rotate(-2.1deg);
  }
  8% {
    transform-origin: bottom;
    transform: rotate(-1.1deg);
  }
  10% {
    transform-origin: bottom;
    transform: rotate(5deg);
  }
  12% {
    transform-origin: bottom;
    transform: rotate(5.1deg);
  }
  14% {
    transform-origin: bottom;
    transform: rotate(3.9deg);
  }
  16% {
    transform-origin: bottom;
    transform: rotate(-3.1deg);
  }
  18% {
    transform-origin: bottom;
    transform: rotate(-4deg);
  }
  20% {
    transform-origin: bottom;
    transform: rotate(-3.6deg);
  }
  22% {
    transform-origin: bottom;
    transform: rotate(-1.9deg);
  }
  24% {
    transform-origin: bottom;
    transform: rotate(2.2deg);
  }
  26% {
    transform-origin: bottom;
    transform: rotate(3deg);
  }
  28% {
    transform-origin: bottom;
    transform: rotate(2.7deg);
  }
  30% {
    transform-origin: bottom;
    transform: rotate(1.5deg);
  }

  32% {
    transform-origin: bottom;
    transform: rotate(-0.2deg);
  }
  34% {
    transform-origin: bottom;
    transform: rotate(-1.6deg);
  }
  36% {
    transform-origin: bottom;
    transform: rotate(-2deg);
  }
  38% {
    transform-origin: bottom;
    transform: rotate(-1.7deg);
  }
  40% {
    transform-origin: bottom;
    transform: rotate(-1.1deg);
  }
  42% {
    transform-origin: bottom;
    transform: rotate(-0.4deg);
  }
  44% {
    transform-origin: bottom;
    transform: rotate(0deg);
  }
  46% {
    transform-origin: bottom;
    transform: rotate(0deg);
  }
  100% {
    transform-origin: bottom;
    transform: rotate(0deg);
  }
}

/**hover */
.online-service ul li:not(:first-child):hover {
  background: #ecf3ff;
}
.online-service ul li:nth-child(2):hover .service-fixed,
.online-service ul li:nth-child(3):hover .service-fixed {
  display: block;
}
.online-service ul li#service-open:hover .service-fixed {
  display: block;
}
.online-service ul li .service-fixed {
  display: none;
}
/**动态效果-抖动 */
.culture-block {
  /*animation-name: shock;*/
  animation-iteration-count: infinite;
  animation-duration: 2000ms;
  animation-direction: normal;
  animation-timing-function: linear;
}

.culture-block:hover {
  animation-name: none;
}

@keyframes shock {
  0% {
    transform-origin: bottom;
    transform: rotate(0);
  }
  2% {
    transform-origin: bottom;
    transform: rotate(-1.2deg);
  }
  4% {
    transform-origin: bottom;
    transform: rotate(-2deg);
  }
  6% {
    transform-origin: bottom;
    transform: rotate(-2.1deg);
  }
  8% {
    transform-origin: bottom;
    transform: rotate(-1.1deg);
  }
  10% {
    transform-origin: bottom;
    transform: rotate(5deg);
  }
  12% {
    transform-origin: bottom;
    transform: rotate(5.1deg);
  }
  14% {
    transform-origin: bottom;
    transform: rotate(3.9deg);
  }
  16% {
    transform-origin: bottom;
    transform: rotate(-3.1deg);
  }
  18% {
    transform-origin: bottom;
    transform: rotate(-4deg);
  }
  20% {
    transform-origin: bottom;
    transform: rotate(-3.6deg);
  }
  22% {
    transform-origin: bottom;
    transform: rotate(-1.9deg);
  }
  24% {
    transform-origin: bottom;
    transform: rotate(2.2deg);
  }
  26% {
    transform-origin: bottom;
    transform: rotate(3deg);
  }
  28% {
    transform-origin: bottom;
    transform: rotate(2.7deg);
  }
  30% {
    transform-origin: bottom;
    transform: rotate(1.5deg);
  }

  32% {
    transform-origin: bottom;
    transform: rotate(-0.2deg);
  }
  34% {
    transform-origin: bottom;
    transform: rotate(-1.6deg);
  }
  36% {
    transform-origin: bottom;
    transform: rotate(-2deg);
  }
  38% {
    transform-origin: bottom;
    transform: rotate(-1.7deg);
  }
  40% {
    transform-origin: bottom;
    transform: rotate(-1.1deg);
  }
  42% {
    transform-origin: bottom;
    transform: rotate(-0.4deg);
  }
  44% {
    transform-origin: bottom;
    transform: rotate(0deg);
  }
  46% {
    transform-origin: bottom;
    transform: rotate(0deg);
  }
  100% {
    transform-origin: bottom;
    transform: rotate(0deg);
  }
}
</style>